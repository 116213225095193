<template>
  <el-card shadow="hover">
    <span class="demonstration"></span>
    <el-row class="row-bg" justify="space-between" type="flex">
      <el-radio-group v-model="radio1" @change="buttonChange">
        <el-radio-button :label="1">新增用户</el-radio-button>
        <el-radio-button :label="2">活跃用户</el-radio-button>
      </el-radio-group>
      <slot />
    </el-row>
    <template #header>
      <span style="font-weight: bold; font-size: 18px">{{ title }}</span>
      <el-tooltip placement="top">
        <div slot="content">提供新增用户和活跃用户的趋势变化及明细数据</div>
        <vab-icon icon="information-line" />
      </el-tooltip>
    </template>
    <vab-chart
      v-loading="loading"
      class="trend-echart"
      :init-options="initOptions"
      lazy
      :option="option"
      style="height: 360px"
      theme="vab-echarts-theme"
    />
  </el-card>
</template>

<script>
  import VabChart from '@/extra/VabChart'

  export default {
    components: {
      VabChart,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      data: {
        type: [Object, Array],
        default: () => {},
      },
    },
    data() {
      return {
        loading: true,
        radio1: 1,
        initOptions: {
          renderer: 'svg',
        },
        timeForm: {
          startTime: '',
          endTime: '',
        },
        option: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                type: 'dashed',
                color: 'rgba(198, 196, 196, 0.75)',
              },
            },
          },
          legend: {
            bottom: -5,
          },
          grid: {
            top: '5%',
            left: '2%',
            right: '4%',
            bottom: '5%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: [],
          },
          yAxis: {
            type: 'value',
          },
          series: {
            name: '新增用户',
            data: [],
            type: 'line',
            emphasis: {
              itemStyle: {
                color: '#409EFF',
                borderColor: 'white',
              },
            },
          },
        },
      }
    },
    watch: {
      data(newVal) {
        this.fetchData(newVal)
      },
    },
    methods: {
      fetchData(data) {
        let user = data.user
        // let region = data.region
        let startTime = []
        for (let i in user) {
          startTime.push(this.timeFormat(user[i].startTime))
        }
        let count = []
        for (let r in user) {
          count.push(user[r].count)
        }
        this.option.xAxis.data = startTime
        this.option.series.data = count
        this.timeForm.startTime = user[0].startTime
        this.timeForm.endTime = user[user.length - 1].endTime
        this.loading = false
      },

      buttonChange(val) {
        if (val == 1) {
          this.$emit('userType', 'newUser')
          this.option.series.name = '新增用户'
        } else {
          this.$emit('userType', 'activeUser')
          this.option.series.name = '活跃用户'
        }
      },

      timeFormat(time) {
        if (time === 0) {
          return 0
        } else {
          let date = new Date(time * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' '
          )
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-card {
      height: 500px !important;
    }
  }
</style>
