var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保 存")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "left",
            "label-width": "80px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户名", prop: "username" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入用户名" },
                model: {
                  value: _vm.form.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "username", $$v)
                  },
                  expression: "form.username",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "UID", prop: "uid" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入UID" },
                model: {
                  value: _vm.form.uid,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "uid", $$v)
                  },
                  expression: "form.uid",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "通道", prop: "channel" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入通道" },
                model: {
                  value: _vm.form.channel,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "channel", $$v)
                  },
                  expression: "form.channel",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "激活码", prop: "cdk" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入激活码" },
                model: {
                  value: _vm.form.cdk,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cdk", $$v)
                  },
                  expression: "form.cdk",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "语言", prop: "lang" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择语言" },
                  model: {
                    value: _vm.form.lang,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "lang", $$v)
                    },
                    expression: "form.lang",
                  },
                },
                _vm._l(_vm.languageList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }