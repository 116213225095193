var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "1450px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        { ref: "table", attrs: { border: "", data: _vm.list } },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "套餐名称",
              prop: "packageName",
              "show-overflow-tooltip": "",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
              prop: "status",
              "show-overflow-tooltip": "",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "总流量",
              prop: "flowTotal",
              "show-overflow-tooltip": "",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "已用流量",
              prop: "used",
              "show-overflow-tooltip": "",
              width: "150",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "激活时间",
              prop: "activationTime",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "失效时间",
              prop: "expireTime",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "更新时间",
              prop: "updatedTime",
              "show-overflow-tooltip": "",
            },
          }),
          _vm.$perms("iot_cardUserInfo_editExpireTime")
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "right",
                  label: "操作",
                  width: "200",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm.$perms("iot_cardUserInfo_editExpireTime")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(row)
                                    },
                                  },
                                },
                                [_vm._v(" 编辑套餐有效期 ")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3990950282
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("Edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }