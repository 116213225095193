<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    :width="dialogWidth"
    @close="close"
  >
    <div v-if="isSetting">
      <el-steps
        v-if="title === '添加'"
        :active="active"
        align-center
        finish-status="success"
        style="padding-bottom: 20px"
      >
        <el-step title="基础信息" />
        <el-step title="设备名称" />
        <el-step title="选择服务器" />
      </el-steps>
      <el-form
        v-show="active == 0"
        ref="form"
        label-width="88px"
        :model="form"
        :rules="rules"
      >
        <el-form-item v-if="!form.isEdit" label="包名" prop="app_bundle_id">
          <el-select
            v-model="form.app_bundle_id"
            allow-create
            filterable
            multiple
            placeholder="请选择或输入包名"
          >
            <el-option
              v-for="item in appBundle"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="设备类型" prop="model">
          <el-select v-model="form.model" placeholder="请选择设备类型">
            <el-option
              v-for="item in modelList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="设备型号" prop="type">
          <el-input v-model.trim="form.type" />
        </el-form-item>
        <el-form-item v-if="title === '编辑'" label="语言" prop="lang">
          <el-select v-model="form.lang" placeholder="请选择语言">
            <el-option
              v-for="item in langList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="title === '编辑'" label="设备名称" prop="name">
          <el-input v-model.trim="form.name" clearable />
        </el-form-item>
        <el-form-item label="版本号" prop="version_number">
          <el-input v-model.trim="form.version_number" clearable />
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model.trim="form.sort" clearable />
        </el-form-item>
        <el-form-item label="config" prop="config">
          <el-input v-model.trim="form.app_device_type__config" clearable />
        </el-form-item>
        <div style="display: flex">
          <el-form-item class="upload" label="图标" prop="fileName">
            <el-upload
              ref="upload"
              action=""
              :auto-upload="false"
              class="upload-file"
              :file-list="fileList"
              :http-request="upLoadIcon"
              list-type="picture-card"
              :on-change="onChange"
              :on-remove="onRemove"
              :show-file-list="false"
            >
              <img v-if="form.icon" :src="form.icon" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item class="upload" label="图标V2" prop="fileNameV2">
            <el-upload
              ref="uploadV2"
              action=""
              :auto-upload="false"
              class="upload-file"
              :file-list="fileListV2"
              :http-request="upLoadIconV2"
              list-type="picture-card"
              :on-change="onChangeV2"
              :on-remove="onRemoveV2"
              :show-file-list="false"
            >
              <img v-if="form.iconV2" :src="form.iconV2" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </div>
      </el-form>
      <el-form
        v-if="active === 1"
        v-show="active === 1"
        ref="form_vice"
        label-width="150px"
        :model="form"
        :rules="rules_vice"
      >
        <el-form-item
          v-for="(language, index) in langList"
          :key="index"
          :label="`设备名称(${language.label})`"
          :prop="`name_${language.value}`"
        >
          <el-input v-model.trim="form.mulName[language.value]" />
        </el-form-item>
      </el-form>
      <el-form
        v-if="active === 2"
        v-show="active === 2"
        ref="form_server"
        class="server-select"
        :model="form"
        :rules="rules_server"
      >
        <el-form-item prop="region">
          <el-checkbox-group v-model="form.region">
            <el-checkbox
              v-for="item in serverList"
              :key="item.value"
              :label="item.value"
            >
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
    </div>
    <div v-else>
      <el-input
        v-model="textarea"
        :autosize="{ minRows: 20, maxRows: 40 }"
        placeholder="请输入内容"
        type="textarea"
      />
    </div>

    <template #footer>
      <span v-show="showEmptySpan"></span>
      <el-button v-show="showPrevButton" type="primary" @click="prev">
        上一步
      </el-button>
      <el-button
        v-if="title === '添加'"
        v-show="showNextButton"
        type="primary"
        @click="next"
      >
        下一步
      </el-button>
      <div v-show="showSaveButton">
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
      <div v-show="showCloseButton">
        <el-button type="primary" @click="close">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import {
    editAppDeviceType,
    batchEditAppDeviceType,
    addAppDeviceType,
  } from '@/api/appDeviceType'
  import network from '@/config/index.js'
  const axios = require('axios')
  import { Loading } from 'element-ui'

  export default {
    name: 'AppDeviceType',
    props: {
      appBundle: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      const validateIcon = (rule, value, callback) => {
        if (this.title === '添加') {
          if (!this.form.fileName && !this.form.fileNameV2) {
            callback(new Error('请至少上传一个图标'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      }
      const validateName = (rule, value, callback) => {
        if (!this.form.mulName.en) {
          callback(new Error('请输入英语'))
        } else {
          callback()
        }
      }
      return {
        dialogWidth: '700px',
        list: [],
        queryForm: { isSelect: 'true' },
        packageList: [],
        fileList: [],
        fileListV2: [],
        modelList: [
          {
            value: 1,
            label: 'DVR',
          },
          {
            value: 2,
            label: 'IPC',
          },
        ],
        langList: [
          {
            label: '简体中文',
            value: 'cn',
          },
          {
            label: '繁体中文',
            value: 'cn_tw',
          },
          {
            label: '英语',
            value: 'en',
          },
          {
            label: '日语',
            value: 'jp',
          },
          {
            label: '俄语',
            value: 'ru',
          },
          {
            label: '葡萄牙语',
            value: 'pt',
          },
          {
            label: '波兰语',
            value: 'pl',
          },
          {
            label: '荷兰语',
            value: 'nl',
          },
          {
            label: '意大利语',
            value: 'it',
          },
          {
            label: '法语',
            value: 'fr',
          },
          {
            label: '西班牙语',
            value: 'es',
          },
          {
            label: '德语',
            value: 'de',
          },
        ],
        serverList: [
          {
            label: '测试服',
            value: 'test',
          },
          {
            label: '国内服',
            value: 'cn',
          },
          {
            label: '美洲服',
            value: 'us',
          },
          {
            label: '欧洲服',
            value: 'eu',
          },
        ],
        form: {
          name: '',
          mulName: {
            en: '',
            cn: '',
            cn_tw: '',
            jp: '',
            ru: '',
            pt: '',
            pl: '',
            nl: '',
            it: '',
            fr: '',
            es: '',
            de: '',
          },
          icon: '',
          iconV2: '',
          region: [],
        },
        rules: {
          type: [
            { required: true, trigger: 'blur', message: '请输入设备型号' },
          ],
          name: [
            { required: true, trigger: 'blur', message: '请输入设备名称' },
          ],
          model: [
            { required: true, trigger: 'blur', message: '请选择设备类型' },
          ],
          lang: [{ required: true, trigger: 'blur', message: '请选择语言' }],
          sort: [{ required: true, trigger: 'blur', message: '请输入排序值' }],
          app_bundle_id: [
            { required: true, trigger: 'blur', message: '请选择包名' },
          ],
          version_number: [
            { required: true, trigger: 'blur', message: '请输入版本号' },
          ],
          fileName: [
            { required: true, validator: validateIcon, trigger: 'blur' },
          ],
        },
        rules_vice: {
          name_en: [
            { required: true, validator: validateName, trigger: 'blur' },
          ],
        },
        rules_server: {
          region: [
            { required: true, trigger: 'blur', message: '请至少选择一项' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        active: 0,
        isSetting: true,
        textarea: '',
      }
    },
    computed: {
      showEmptySpan() {
        return this.active === 0 || this.form.isEdit || !this.isSetting
      },
      showPrevButton() {
        return this.active !== 0 && this.isSetting
      },
      showNextButton() {
        return this.active !== 2 && !this.form.isEdit && this.isSetting
      },
      showSaveButton() {
        return (this.active === 2 && this.isSetting) || this.form.isEdit
      },
      showCloseButton() {
        return !this.isSetting
      },
    },
    methods: {
      showEdit(row) {
        if (!row) {
          this.$set(
            this.form,
            'app_bundle_id',
            this.form.app_bundle_id ? [] : ''
          )
          this.title = '添加'
        } else {
          this.title = '编辑'
          this.form = Object.assign({}, row)
          this.form.isEdit = true
          this.form.app_device_type__config = JSON.stringify(
            this.form.app_device_type__config
          )
        }
        this.active = 0
        this.dialogFormVisible = true
      },
      showEditBatch(rows, selectTypeId, selectLanguageId) {
        this.title = '批量编辑'
        this.active = 0
        this.form = Object.assign({}, rows[0])
        this.form.app_device_type__id = selectTypeId
        this.form.app_device_type__devicenamelanguage__id = selectLanguageId
        this.form.app_device_type__config = JSON.stringify(
          this.form.app_device_type__config
        )
        this.form.isEdit = true
        this.form.mulName = {}
        this.dialogFormVisible = true
      },
      close() {
        this.dialogFormVisible = false
        if (this.title === '添加') {
          this.textarea = ''
          this.isSetting = true
        }
        if (this.title === '编辑') {
          this.form.isEdit = false
          this.$refs['form'].resetFields()
        }
        this.form = this.$options.data().form
      },
      prev() {
        this.active = this.active - 1
      },
      next() {
        if (this.active === 0) {
          this.$refs['form'].validate(async (valid) => {
            if (valid) {
              this.active = this.active + 1
            }
          })
        } else if (this.active === 1) {
          this.$refs['form_vice'].validate(async (valid) => {
            if (valid) {
              this.active = this.active + 1
            }
          })
        }
      },
      save() {
        if (this.title === '添加') {
          this.$refs['form_server'].validate(async (valid) => {
            if (valid) {
              let loadingInstance
              try {
                this.formData = new FormData()
                // 填充多语言名称
                const mulName = Object.entries(this.form.mulName)
                mulName.forEach(([key, value]) => {
                  if (value === '' && key !== 'en') {
                    this.form.mulName[key] = this.form.mulName.en
                  }
                })
                // 添加表单数据
                this.formData.append('name', JSON.stringify(this.form.mulName))
                this.formData.append('type', this.form.type)
                this.formData.append('sort', this.form.sort)
                this.formData.append('model', this.form.model)
                if (this.form.app_device_type__config) {
                  this.formData.append(
                    'config',
                    this.form.app_device_type__config
                  )
                }
                this.formData.append('versionNumber', this.form.version_number)
                this.formData.append('region', this.form.region)
                const appBundleId = this.form.app_bundle_id.join(',')
                this.formData.append('appBundleName', appBundleId)
                // 显示加载动画
                loadingInstance = Loading.service({
                  lock: true,
                  text: '添加中...',
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)',
                })

                // 提交上传
                await Promise.all([
                  this.$refs.upload.submit(),
                  this.$refs.uploadV2.submit(),
                ])

                // API 调用
                const { data, error_code, msg } = await addAppDeviceType(
                  this.formData
                )
                if (error_code === 0) {
                  this.$message({
                    type: 'success',
                    message: '添加成功!',
                  })
                  this.$emit('fetch-data')
                  this.textarea = JSON.stringify(data.list)
                  this.isSetting = false
                } else {
                  throw new Error('添加失败! ' + msg)
                }
              } catch (error) {
                this.$message({
                  type: 'error',
                  message: error.message,
                })
                this.form.app_bundle_id = JSON.parse(this.form.app_bundle_id)
              } finally {
                if (loadingInstance) {
                  loadingInstance.close() // 确保加载动画关闭
                }
              }
            }
          })
        } else if (this.title === '编辑') {
          this.$refs['form'].validate(async (valid) => {
            if (valid) {
              this.formData = new FormData()
              this.formData.append('name', this.form.name)
              this.formData.append('type', this.form.type)
              this.formData.append('sort', this.form.sort)
              this.formData.append('model', this.form.model)
              if (this.form.app_device_type__config) {
                this.formData.append(
                  'config',
                  this.form.app_device_type__config
                )
              }
              this.formData.append('version_number', this.form.version_number)
              this.formData.append(
                'app_device_type__id',
                this.form.app_device_type__id
              )
              this.formData.append('lang', this.form.lang)
              this.formData.append(
                'app_device_type__devicenamelanguage__id',
                this.form.app_device_type__devicenamelanguage__id
              )
              if (this.form.fileName) {
                this.$refs.upload.submit()
              }
              if (this.form.fileNameV2) {
                this.$refs.uploadV2.submit()
              }
              const { msg } = await editAppDeviceType(this.formData)
              this.$baseMessage(msg, 'success', 'vab-hey-message-success')
              this.$emit('fetch-data')
              this.close()
            }
          })
        } else {
          this.$refs['form'].validate(async (valid) => {
            if (valid) {
              this.formData = new FormData()
              this.formData.append('type', this.form.type)
              this.formData.append('sort', this.form.sort)
              this.formData.append('model', this.form.model)
              if (this.form.app_device_type__config) {
                this.formData.append(
                  'config',
                  this.form.app_device_type__config
                )
              }
              this.formData.append('version_number', this.form.version_number)
              this.formData.append(
                'app_device_type__id',
                this.form.app_device_type__id
              )
              this.formData.append(
                'app_device_type__devicenamelanguage__id',
                this.form.app_device_type__devicenamelanguage__id
              )
              this.$refs.upload.submit()
              this.$refs.uploadV2.submit()
              const { msg } = await batchEditAppDeviceType(this.formData)
              this.$baseMessage(msg, 'success', 'vab-hey-message-success')
              this.$emit('fetch-data')
              this.close()
            }
          })
        }
      },
      upLoadIcon(file) {
        if (this.title === '添加') {
          this.formData.append('iconFile', file.file)
        } else {
          this.formData.append('icon', file.file)
        }
      },
      upLoadIconV2(file) {
        if (this.title === '添加') {
          this.formData.append('iconV2File', file.file)
        } else {
          this.formData.append('iconV2', file.file)
        }
      },
      onChange(file, list) {
        this.form.fileName = file.name
        if (list.length > 1 && file.status !== 'fail') {
          list.splice(0, 1)
        }
        this.form.icon = URL.createObjectURL(file.raw)
        this.$refs['form'].clearValidate(['fileName'])
      },
      onChangeV2(file, list) {
        this.form.fileNameV2 = file.name
        if (list.length > 1 && file.status !== 'fail') {
          list.splice(0, 1)
        }
        this.form.iconV2 = URL.createObjectURL(file.raw)
        this.$refs['form'].clearValidate(['fileNameV2'])
      },
      onRemove() {
        this.form.fileName = null
      },
      onRemoveV2() {
        this.form.fileNameV2 = null
      },
      submitUpload() {
        this.$confirm('是否上传文件?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            // 构建传给后端的数据
            this.formData = new FormData()
            this.formData.append('name', this.form.name)
            this.formData.append('type', this.form.type)
            this.formData.append('sort', this.form.sort)
            this.formData.append('lang', this.form.lang)
            this.formData.append('model', this.form.model)
            this.formData.append('version_number', this.form.version_number)
            if (
              null != this.form.app_bundle_id &&
              '' != this.form.app_bundle_id
            ) {
              // 将对象转为字符串
              this.form.app_bundle_id = JSON.stringify(this.form.app_bundle_id)
            }
            this.formData.append('app_bundle_id', this.form.app_bundle_id)
            this.$refs.upload.submit()
            this.$refs.uploadV2.submit()
            let url = network.baseURL + '/deviceManagement/callAddAppDeviceType'
            let headers = {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
            axios
              .post(url, this.formData, headers)
              .then(
                function (res) {
                  if (res.data.code === 0) {
                    this.$message({
                      type: 'success',
                      message: '文件上传成功!',
                    })
                    this.$emit('fetch-data')
                    this.close()
                  } else {
                    this.$message({
                      type: 'error',
                      message: '文件上传失败! ' + res.data.msg,
                    })
                  }
                }.bind(this)
              )
              .catch((res) => {
                console.log(res)
                this.form.app_bundle_id = this.form.app_bundle_id.replace(
                  '[',
                  ''
                )
                this.form.app_bundle_id = this.form.app_bundle_id.replace(
                  ']',
                  ''
                )
                this.form.app_bundle_id = this.form.app_bundle_id.split(',')
                let data = this.packageList
                let forData = this.form.app_bundle_id
                let r = 0
                let obj = []
                for (let i in data) {
                  if (data[i].id == forData[r]) {
                    obj[r] = data[i].app_bundle_id
                    i++
                    r++
                  } else {
                    i++
                  }
                }
                this.form.app_bundle_id = obj
              })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '取消上传',
            })
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-form-item__content {
    margin-right: 66px;
  }
  ::v-deep .el-upload {
    padding: 10px 0;
  }
  ::v-deep .el-dialog__footer {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .el-dialog__body {
    padding-top: 0;
  }
  .upload {
    height: 80px;
  }
  ::v-deep .el-upload--picture-card {
    width: 100px;
    height: 100px;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #c0ccda;
  }
  .server-select {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::v-deep .el-form-item__error {
    width: max-content;
  }
</style>
