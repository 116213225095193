var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 6, md: 12, sm: 24, xl: 6, xs: 24 } },
            [
              _c(
                "el-card",
                { staticClass: "order-card1", attrs: { shadow: "hover" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("vab-icon", {
                        attrs: { icon: "shopping-bag-2-line" },
                      }),
                      _vm._v(" 新增用户 "),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("p", [_vm._v("昨日")]),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "h1",
                          [
                            _c("vab-count", {
                              attrs: {
                                decimals: _vm.countConfig.decimals,
                                duration: _vm.countConfig.duration,
                                "end-val": _vm.countConfig.endVal,
                                prefix: _vm.countConfig.prefix,
                                separator: _vm.countConfig.separator,
                                "start-val": _vm.countConfig.startVal,
                                suffix: _vm.countConfig.suffix,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 6, md: 12, sm: 24, xl: 6, xs: 24 } },
            [
              _c(
                "el-card",
                { staticClass: "order-card1", attrs: { shadow: "hover" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("vab-icon", {
                        attrs: { icon: "shopping-bag-2-line" },
                      }),
                      _vm._v(" 活跃用户 "),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("p", [_vm._v("昨日")]),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "h1",
                          [
                            _c("vab-count", {
                              attrs: {
                                decimals: _vm.countConfig.decimals,
                                duration: _vm.countConfig.duration,
                                "end-val": _vm.countConfig.endVal,
                                prefix: _vm.countConfig.prefix,
                                separator: _vm.countConfig.separator,
                                "start-val": _vm.countConfig.startVal,
                                suffix: _vm.countConfig.suffix,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 6, md: 12, sm: 24, xl: 6, xs: 24 } },
            [
              _c(
                "el-card",
                { staticClass: "order-card1", attrs: { shadow: "hover" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("vab-icon", {
                        attrs: { icon: "shopping-bag-2-line" },
                      }),
                      _vm._v(" 累计用户 "),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("p", [_vm._v("昨日")]),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "h1",
                          [
                            _c("vab-count", {
                              attrs: {
                                decimals: _vm.countConfig.decimals,
                                duration: _vm.countConfig.duration,
                                "end-val": _vm.countConfig.endVal,
                                prefix: _vm.countConfig.prefix,
                                separator: _vm.countConfig.separator,
                                "start-val": _vm.countConfig.startVal,
                                suffix: _vm.countConfig.suffix,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 6, md: 12, sm: 24, xl: 6, xs: 24 } },
            [
              _c(
                "el-card",
                { staticClass: "order-card1", attrs: { shadow: "hover" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("vab-icon", {
                        attrs: { icon: "shopping-bag-2-line" },
                      }),
                      _vm._v(" 设备新增用户 "),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("p", [_vm._v("昨日")]),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "h1",
                          [
                            _c("vab-count", {
                              attrs: {
                                decimals: _vm.countConfig.decimals,
                                duration: _vm.countConfig.duration,
                                "end-val": _vm.countConfig.endVal,
                                prefix: _vm.countConfig.prefix,
                                separator: _vm.countConfig.separator,
                                "start-val": _vm.countConfig.startVal,
                                suffix: _vm.countConfig.suffix,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 6, md: 12, sm: 24, xl: 6, xs: 24 } },
            [
              _c(
                "el-card",
                { staticClass: "order-card1", attrs: { shadow: "hover" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("vab-icon", {
                        attrs: { icon: "shopping-bag-2-line" },
                      }),
                      _vm._v(" 设备活跃用户 "),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("p", [_vm._v("昨日")]),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "h1",
                          [
                            _c("vab-count", {
                              attrs: {
                                decimals: _vm.countConfig.decimals,
                                duration: _vm.countConfig.duration,
                                "end-val": _vm.countConfig.endVal,
                                prefix: _vm.countConfig.prefix,
                                separator: _vm.countConfig.separator,
                                "start-val": _vm.countConfig.startVal,
                                suffix: _vm.countConfig.suffix,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 6, md: 12, sm: 24, xl: 6, xs: 24 } },
            [
              _c(
                "el-card",
                { staticClass: "order-card1", attrs: { shadow: "hover" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("vab-icon", {
                        attrs: { icon: "shopping-bag-2-line" },
                      }),
                      _vm._v(" 设备累计用户 "),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("p", [_vm._v("昨日")]),
                      ]),
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c(
                          "h1",
                          [
                            _c("vab-count", {
                              attrs: {
                                decimals: _vm.countConfig.decimals,
                                duration: _vm.countConfig.duration,
                                "end-val": _vm.countConfig.endVal,
                                prefix: _vm.countConfig.prefix,
                                separator: _vm.countConfig.separator,
                                "start-val": _vm.countConfig.startVal,
                                suffix: _vm.countConfig.suffix,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }