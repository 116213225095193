var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "echarts-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [_c("data-picker"), _c("chart-table")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }