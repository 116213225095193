var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-col",
    { attrs: { lg: 12, md: 12, sm: 12, xl: 12, xs: 12 } },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.allFold,
              expression: "!allFold",
            },
          ],
          attrs: { height: _vm.height, shadow: "hover" },
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleOpen } },
            [
              _c("vab-icon", {
                staticClass: "vab-dropdown",
                class: { "vab-dropdown-active": _vm.fold },
                attrs: { icon: "arrow-up-s-line" },
              }),
              _vm._v(" 各型号订单量占比 "),
            ],
            1
          ),
          _c(
            "el-tooltip",
            { attrs: { placement: "top" } },
            [
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _vm._v("不同型号云存储的订单量占比"),
              ]),
              _c("vab-icon", {
                staticStyle: { color: "#409eff" },
                attrs: { icon: "information-line" },
              }),
            ],
            1
          ),
          _c(
            "el-tabs",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.fold,
                  expression: "!fold",
                },
              ],
              attrs: { height: _vm.height },
            },
            [
              _c("vab-chart", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: {
                  "init-options": _vm.initOptions,
                  lazy: "",
                  option: _vm.option,
                  theme: "vab-echarts-theme",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }