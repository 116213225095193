import { render, staticRenderFns } from "./batchOrderFlowPackage.vue?vue&type=template&id=168342fd"
import script from "./batchOrderFlowPackage.vue?vue&type=script&lang=js"
export * from "./batchOrderFlowPackage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/web/admin-pro-master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('168342fd')) {
      api.createRecord('168342fd', component.options)
    } else {
      api.reload('168342fd', component.options)
    }
    module.hot.accept("./batchOrderFlowPackage.vue?vue&type=template&id=168342fd", function () {
      api.rerender('168342fd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/iotCardServe/cardUserInformation/components/batchOrderFlowPackage.vue"
export default component.exports