var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "400px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("重 置")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload",
                  attrs: {
                    accept: ".txt",
                    action: "string",
                    "auto-upload": false,
                    "http-request": _vm.upLoadFile,
                    limit: 1,
                    "on-exceed": _vm.handleExceed,
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "upload_button",
                      attrs: {
                        slot: "trigger",
                        size: "small",
                        type: "primary",
                      },
                      slot: "trigger",
                    },
                    [_vm._v(" 选择txt文件 ")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticClass: "upload_select",
                      attrs: { placeholder: "请选择是否解绑出厂无限流量" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.typeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }