var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-management-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: 15 } },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: false, model: _vm.queryForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("el-tag", [_vm._v("最大标签数")]),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入最大标签数",
                        },
                        model: {
                          value: _vm.queryForm.maxLabels,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "maxLabels",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.maxLabels",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-tag", [_vm._v("最小辨识度")]),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入最小辨识度",
                        },
                        model: {
                          value: _vm.queryForm.minConfidence,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "minConfidence",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.minConfidence",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-s-promotion",
                            type: "primary",
                          },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" 提交 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: {
                    action: "",
                    "auto-upload": false,
                    "file-list": _vm.fileList,
                    headers: _vm.headers,
                    "http-request": _vm.upLoadFile,
                    "list-type": "picture",
                    "on-preview": _vm.handlePreview,
                    "on-remove": _vm.handleRemove,
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("选择图片")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm._v(
                        " 上传单张图片, 必须是 .jpeg 或 .png 格式, 不得大于5MB "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { margin: "50px 0" } }),
              _c("el-input", {
                attrs: {
                  id: "uploadRes",
                  autosize: { minRows: 15, maxRows: 100 },
                  clearable: "",
                  placeholder: "响应数据",
                  type: "textarea",
                },
                model: {
                  value: _vm.textarea,
                  callback: function ($$v) {
                    _vm.textarea = $$v
                  },
                  expression: "textarea",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }