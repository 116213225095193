var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.dialogVisible, width: "1500px" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-descriptions",
        {
          staticStyle: { "padding-bottom": "20px" },
          attrs: { border: "", column: 8, direction: "vertical" },
        },
        _vm._l(_vm.commonColumns, function (item) {
          return _c(
            "el-descriptions-item",
            { key: item.val, attrs: { label: item.label, span: item.span } },
            [
              item.label === "更新时间"
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("timeFormat")(_vm.list[item.val])) +
                        " "
                    ),
                  ])
                : item.label === "是否支持4G" ||
                  item.label === "是否支持自定义语音" ||
                  item.label === "是否支持云台"
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("supportFormat")(_vm.list[item.val])) +
                        " "
                    ),
                  ])
                : _c("span", [_vm._v(" " + _vm._s(_vm.list[item.val]) + " ")]),
            ]
          )
        }),
        1
      ),
      _c(
        "el-table",
        { ref: "table", attrs: { border: "", data: _vm.list.uid_push } },
        _vm._l(_vm.tabelColumns, function (item) {
          return _c("el-table-column", {
            key: item.prop,
            attrs: {
              align: "center",
              formatter: item.formatter,
              label: item.label,
              prop: item.prop,
              width: item.width,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      item.label === "添加时间" || item.label === "更新时间"
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("timeFormat")(scope.row[item.prop])
                                ) +
                                " "
                            ),
                          ])
                        : item.label === "app类型"
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("appTypeFormat")(scope.row[item.prop])
                                ) +
                                " "
                            ),
                          ])
                        : item.label === "推送类型"
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("pushTypeFormat")(scope.row[item.prop])
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [
                            _vm._v(" " + _vm._s(scope.row[item.prop]) + " "),
                          ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }