var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "100px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "AI套餐ID", prop: "aiMealID" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    disabled: _vm.form.selectStoreMealID,
                    placeholder: "请选择套餐ID",
                  },
                  model: {
                    value: _vm.form.aiMealID,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "aiMealID", $$v)
                    },
                    expression: "form.aiMealID",
                  },
                },
                _vm._l(_vm.aiStoreMealList, function (item) {
                  return _c(
                    "el-option",
                    { key: item.id, attrs: { label: item.id, value: item.id } },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v(_vm._s(item.id)),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            "font-size": "12px",
                            color: "#8492a6",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.lang__title) + " ")]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "套餐语言", prop: "lang" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入套餐语言" },
                model: {
                  value: _vm.form.lang,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "lang",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.lang",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "套餐标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入套餐标题" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "title",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "套餐描述", prop: "content" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入套餐描述" },
                model: {
                  value: _vm.form.content,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "content",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "优惠描述", prop: "discountContent" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入优惠描述" },
                model: {
                  value: _vm.form.discountContent,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "discountContent",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.discountContent",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }