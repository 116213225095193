var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            [
              _c(
                "el-card",
                { staticClass: "order-card1", attrs: { shadow: "hover" } },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "font-size": "20px",
                        "font-weight": "600",
                        margin: "0 auto",
                        "text-align": "center",
                      },
                    },
                    [
                      _c("vab-icon", {
                        staticStyle: {
                          "font-size": "30px",
                          "font-weight": "500",
                        },
                        attrs: { icon: "user-add-line" },
                      }),
                      _vm._v(" 日增销售额 "),
                      _c(
                        "el-tooltip",
                        { attrs: { placement: "top" } },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [_vm._v(" 昨日平台增加的销售额。 "), _c("br")]
                          ),
                          _c("vab-icon", {
                            attrs: { icon: "information-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c("p", [_vm._v("昨日")]),
                      ]),
                      _c("el-tooltip", { attrs: { placement: "top" } }, [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [_vm._v("人民币")]
                        ),
                        _c(
                          "h1",
                          [
                            _vm._v(" ¥ "),
                            _c("vab-count", {
                              attrs: {
                                decimals: _vm.countConfig.decimals,
                                duration: _vm.countConfig.duration,
                                "end-val": _vm.countConfig.endVal,
                                prefix: _vm.countConfig.prefix,
                                separator: _vm.countConfig.separator,
                                "start-val": _vm.countConfig.startVal,
                                suffix: _vm.countConfig.suffix,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c("p", [_vm._v("昨日")]),
                      ]),
                      _c("el-tooltip", { attrs: { placement: "top" } }, [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [_vm._v("美元")]
                        ),
                        _c(
                          "h1",
                          [
                            _vm._v(" $ "),
                            _c("vab-count", {
                              attrs: {
                                decimals: _vm.countConfig2.decimals,
                                duration: _vm.countConfig2.duration,
                                "end-val": _vm.countConfig2.endVal,
                                prefix: _vm.countConfig2.prefix,
                                separator: _vm.countConfig2.separator,
                                "start-val": _vm.countConfig2.startVal,
                                suffix: _vm.countConfig2.suffix,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }