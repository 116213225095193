var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "添加", visible: _vm.dialogFormVisible, width: "500px" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保 存")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "left",
            "label-width": "110px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "生产编码", prop: "orderNumber" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.orderNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "orderNumber", $$v)
                  },
                  expression: "form.orderNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户名", prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "生产数量", prop: "quantity" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.quantity,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "quantity", $$v)
                  },
                  expression: "form.quantity",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户邮箱", prop: "email" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "国家代码", prop: "countryCode" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.countryCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "countryCode", $$v)
                  },
                  expression: "form.countryCode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }