var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "echarts-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 8, md: 8, sm: 8, xl: 8, xs: 8 } },
            [_c("new-user")],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, md: 8, sm: 8, xl: 8, xs: 8 } },
            [_c("active-user")],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, md: 8, sm: 8, xl: 8, xs: 8 } },
            [_c("total-user")],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 8, md: 8, sm: 8, xl: 8, xs: 8 } },
            [_c("devi-new-user")],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, md: 8, sm: 8, xl: 8, xs: 8 } },
            [_c("devi-active-user")],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, md: 8, sm: 8, xl: 8, xs: 8 } },
            [_c("devi-total-user")],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 4, md: 4, sm: 4, xl: 4, xs: 4 } },
            [_c("day-sales-vol")],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, md: 8, sm: 8, xl: 8, xs: 8 } },
            [_c("day-bar", { attrs: { title: "各服务占比" } })],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 4, md: 4, sm: 4, xl: 4, xs: 4 } },
            [_c("total-sales-vol")],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, md: 8, sm: 8, xl: 8, xs: 8 } },
            [_c("total-bar", { attrs: { title: "各服务占比" } })],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 24, md: 24, sm: 24, xl: 24, xs: 24 } },
            [_c("sales-line", { attrs: { title: "销售额趋势" } })],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 12, md: 12, sm: 12, xl: 12, xs: 12 } },
            [_c("new-region-pie", { attrs: { title: "新增用户地区" } })],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 12, md: 12, sm: 12, xl: 12, xs: 12 } },
            [_c("total-region-pie", { attrs: { title: "总用户地区" } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }