<template>
  <div class="echarts-container">
    <el-card shadow="hover">
      <el-row>
        <el-button type="primary" @click="handleOpen">
          <vab-icon
            class="vab-dropdown"
            :class="{ 'vab-dropdown-active': fold }"
            icon="arrow-up-s-line"
          />
          地区统计
        </el-button>
        <el-tooltip placement="top">
          <div slot="content">
            选定时间内新增用户或活跃用户所在地区统计
            <br />
            注意：未知国家是由于当时并未统计特定国家
          </div>
          <vab-icon icon="information-line" style="color: #409eff" />
        </el-tooltip>
      </el-row>
      <el-row v-show="!fold" :height="height" style="margin: 15px 0 0">
        <el-row :gutter="20">
          <el-col :lg="8" :md="12" :sm="24" :xl="6" :xs="24">
            <chart-pie :data="data" />
          </el-col>
          <el-col :lg="16" :md="12" :sm="24" :xl="18" :xs="24">
            <chart-table :table-columns="tableColumns" :table-data="data" />
          </el-col>
        </el-row>
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import chartPie from './chartPie'
  import chartTable from './table'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Echarts',
    components: {
      chartPie,
      chartTable,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        fold: false,
        height: this.$baseTableHeight(3) - 30,
        tableColumns: [
          {
            prop: 'countryName',
            label: '地区名',
          },
          {
            prop: 'count,rate',
            label: '新增用户（百分比）',
            type: 'rate',
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        status: 'dataStat/userDataStatus',
      }),
    },
    watch: {
      status(newVal) {
        this.tableColumns[1].label = newVal
      },
    },
    methods: {
      handleOpen() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
    },
  }
</script>
