var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs" },
    [
      _c(
        "el-card",
        { staticClass: "tabs-card", attrs: { shadow: "hover" } },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { data: _vm.tableData, height: "280" },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "类型名", prop: "type" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "新增设备（百分比）", prop: "count,rate" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.count) +
                                " (" +
                                _vm._s(scope.row.rate) +
                                "%) "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }