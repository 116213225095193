import { render, staticRenderFns } from "./chartPiee.vue?vue&type=template&id=2813073e"
import script from "./chartPiee.vue?vue&type=script&lang=js"
export * from "./chartPiee.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/web/admin-pro-master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2813073e')) {
      api.createRecord('2813073e', component.options)
    } else {
      api.reload('2813073e', component.options)
    }
    module.hot.accept("./chartPiee.vue?vue&type=template&id=2813073e", function () {
      api.rerender('2813073e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dataStat/serviceDataStat/components/chartPiee.vue"
export default component.exports