var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保 存")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "120px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "语音标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入语音标题" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "语音类型", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择语音类型" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.voiceType, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "语音分类", prop: "classification" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择语音分类" },
                  model: {
                    value: _vm.form.classification,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "classification", $$v)
                    },
                    expression: "form.classification",
                  },
                },
                _vm._l(_vm.classification, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          !_vm.edit && _vm.form.classification === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "uid", prop: "uid" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入uid" },
                    model: {
                      value: _vm.form.uid,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "uid",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.uid",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "关联算法类型", prop: "algorithmType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择关联算法类型" },
                  model: {
                    value: _vm.form.algorithmType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "algorithmType", $$v)
                    },
                    expression: "form.algorithmType",
                  },
                },
                _vm._l(_vm.algorithmType, function (item) {
                  return _c("el-option", {
                    key: item.algorithm_type_id,
                    attrs: { label: item.title, value: item.algorithm_type_id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择语音状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.status, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "语言", prop: "language" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择语言" },
                  model: {
                    value: _vm.form.language,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "language", $$v)
                    },
                    expression: "form.language",
                  },
                },
                _vm._l(_vm.lang, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "设备类型", prop: "device_types" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    multiple: "",
                    placeholder: "请选择设备类型",
                  },
                  model: {
                    value: _vm.form.device_types,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "device_types", $$v)
                    },
                    expression: "form.device_types",
                  },
                },
                _vm._l(_vm.deviceType, function (item) {
                  return _c("el-option", {
                    key: item.type,
                    attrs: { label: item.name, value: item.type },
                  })
                }),
                1
              ),
            ],
            1
          ),
          !_vm.edit
            ? _c(
                "el-form-item",
                { attrs: { label: "语音文件", prop: "voiceFile" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload",
                      attrs: {
                        accept: "audio/*",
                        action: "/upload",
                        "auto-upload": false,
                        "http-request": _vm.upLoadFile,
                        limit: 1,
                        "on-change": _vm.handleChange,
                        "on-exceed": _vm.handleExceed,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "trigger",
                            size: "small",
                            type: "primary",
                          },
                          slot: "trigger",
                        },
                        [_vm._v("选择文件")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }