import request from '@/utils/request'

export function getDeviceInfoList(params) {
  return request({
    url: '/deviceManagement/getDeviceInfoList',
    method: 'get',
    params,
  })
}

export function getAppNameList(params) {
  return request({
    url: 'deviceManagement/getAppNameList',
    method: 'get',
    params,
  })
}

export function editAppDeviceType(data) {
  return request({
    url: '/deviceManagement/editAppDeviceType',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}

export function batchEditAppDeviceType(data) {
  return request({
    url: '/deviceManagement/batchEditAppDeviceType',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}

export function deleteAppDeviceType(params) {
  return request({
    url: '/deviceManagement/deleteAppDeviceType',
    method: 'get',
    params,
  })
}

export function deleteDevice(params) {
  return request({
    url: '/deviceManagement/deleteDevice',
    method: 'get',
    params,
  })
}

export function resetPrimaryUser(params) {
  return request({
    url: '/deviceManagement/resetPrimaryUser',
    method: 'get',
    params,
  })
}

export function resetVod(params) {
  return request({
    url: '/deviceManagement/resetVod',
    method: 'get',
    params,
  })
}

export function resetAi(params) {
  return request({
    url: '/deviceManagement/resetAi',
    method: 'get',
    params,
  })
}

export function getAppDeviceTypeList(params) {
  return request({
    url: 'deviceManagement/getAppDeviceTypeList',
    method: 'get',
    params,
  })
}

export function getUidSetList(params) {
  return request({
    url: '/deviceManagement/getUidSetList',
    method: 'get',
    params,
  })
}

export function addAppDeviceType(data) {
  return request({
    url: '/deviceManagement/callAddAppDeviceType',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    timeout: 0,
  })
}

export function getAppBudle(params) {
  return request({
    url: 'deviceManagement/getAppBundle',
    method: 'get',
    params,
  })
}
