<template>
  <el-dialog :title="title" :visible.sync="visible" width="800px" @close="close">
    <el-form ref="form" label-width="120px" :model="formData" :rules="rules">
      <el-row gutter="20">
        <el-col :span="12">
          <el-form-item label="设备规格码" prop="dCode">
            <el-input v-model.trim="formData.dCode" :disabled="isDisabled" />
          </el-form-item>
          <el-form-item label="设备版本" prop="softwareVer">
            <el-input v-model.trim="formData.softwareVer" :disabled="isDisabled" />
          </el-form-item>
          <el-form-item label="屏幕通道数" prop="screenChannels">
            <el-input v-model.trim="formData.screenChannels" />
          </el-form-item>
          <el-form-item label="设备类型" prop="deviceType">
            <el-input v-model.trim="formData.deviceType" />
          </el-form-item>
          <el-form-item label="分辨率" prop="resolution">
            <el-input v-model.trim="formData.resolution" />
          </el-form-item>
          <el-form-item label="固件版本" prop="firmwareVer">
            <el-input v-model.trim="formData.firmwareVer" />
          </el-form-item>
          <el-form-item label="检测类型" prop="aiType">
            <el-input v-model.trim="formData.aiType" />
          </el-form-item>
          <el-form-item label="其它功能" prop="otherFeatures">
            <el-input v-model.trim="formData.otherFeatures" />
          </el-form-item>
          <el-form-item label="网络类型" prop="networkType">
            <el-select v-model="formData.networkType" clearable filterable placeholder="选择网络类型">
              <el-option v-for="item in networkList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="编码类型" prop="videoCode">
            <el-select v-model="formData.videoCode" clearable filterable placeholder="选择编码类型">
              <el-option v-for="item in videoCodeList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="Alexa区域" prop="app_bundle_type">
            <el-select v-model="formData.regionAlexa" clearable filterable placeholder="请选择Alexa区域">
              <el-option v-for="item in appList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 布尔类型字段 -->
          <el-form-item label="人形追踪" prop="supportsHumanTracking">
            <el-radio-group v-model="formData.supportsHumanTracking">
              <el-radio :label="1">支持</el-radio>
              <el-radio :label="0">不支持</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="自定义语音" prop="supportsCustomVoice">
            <el-radio-group v-model="formData.supportsCustomVoice">
              <el-radio :label="1">支持</el-radio>
              <el-radio :label="0">不支持</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="双频Wifi" prop="supportsDualBandWifi">
            <el-radio-group v-model="formData.supportsDualBandWifi">
              <el-radio :label="1">支持</el-radio>
              <el-radio :label="0">不支持</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="4G" prop="supports4g">
            <el-radio-group v-model="formData.supports4g">
              <el-radio :label="1">支持</el-radio>
              <el-radio :label="0">不支持</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="云台" prop="supportsPTZ">
            <el-radio-group v-model="formData.supportsPTZ">
              <el-radio :label="1">支持</el-radio>
              <el-radio :label="0">不支持</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="AI" prop="supportsAi">
            <el-radio-group v-model="formData.supportsAi">
              <el-radio :label="1">支持</el-radio>
              <el-radio :label="0">不支持</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="声光报警" prop="supportsAlarm">
            <el-select v-model="formData.supportsAlarm" placeholder="请选择">
              <el-option :label="'未设置'" :value="-1" />
              <el-option :label="'声光都不支持'" :value="0" />
              <el-option :label="'支持声音'" :value="1" />
              <el-option :label="'支持光'" :value="2" />
              <el-option :label="'支持声光'" :value="3" />
            </el-select>
          </el-form-item>

          <el-form-item label="夜视模式" prop="supportsNightVision">
            <el-select v-model="formData.supportsNightVision" placeholder="请选择">
              <el-option :label="'未设置'" :value="-1" />
              <el-option :label="'黑光（黑白全彩都没有）'" :value="0" />
              <el-option :label="'黑白'" :value="1" />
              <el-option :label="'全彩'" :value="2" />
              <el-option :label="'黑白+全彩'" :value="3" />
            </el-select>
          </el-form-item>

          <el-form-item label="云存储" prop="supportsCloudStorage">
            <el-radio-group v-model="formData.supportsCloudStorage">
              <el-radio :label="1">支持</el-radio>
              <el-radio :label="0">不支持</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="预置点" prop="supportsFourPoint">
            <el-radio-group v-model="formData.supportsFourPoint">
              <el-radio :label="1">支持</el-radio>
              <el-radio :label="0">不支持</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="Alexa" prop="supportsAlexa">
            <el-radio-group v-model="formData.supportsAlexa">
              <el-radio :label="1">支持</el-radio>
              <el-radio :label="0">不支持</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 操作按钮 -->
      <div style="text-align: right">
        <el-button type="default" @click="close">取消</el-button>
        <el-button type="primary" @click="handleSave">保存</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
  import { addDeviceVerInfo, editDeviceVerInfo } from '@/api/deviceVerInfo'
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        dialogFormVisible: false,
        isDisabled: false, // 初始状态为可输入
        initialFormData: {
          dCode: '',
          softwareVer: '',
          firmwareVer: '',
          videoCode: '',
          regionAlexa: '',
          deviceType: '',
          resolution: '',
          screenChannels: '',
          networkType: '',
          otherFeatures: '',
          supportsHumanTracking: 0,
          supportsCustomVoice: 0,
          supportsDualBandWifi: 0,
          supports4g: 0,
          supportsPTZ: 0,
          supportsAi: 0,
          supportsAlexa: 0,
          supportsAlarm: 0,
          supportsNightVision: 0,
          supportsCloudStorage: 0,
          supportsFourPoint: 0,
        },
        formData: { ...this.initialFormData },
        rules: {
          dCode: [{ required: true, message: '请输入设备规格码', trigger: 'blur' }],
          softwareVer: [{ required: true, message: '请输入设备版本', trigger: 'blur' }],
          firmwareVer: [{ required: false, message: '请输入固件版本', trigger: 'blur' }],
          videoCode: [{ required: true, message: '请输入编码类型', trigger: 'blur' }],
          regionAlexa: [
            {
              required: false,
              message: '请选择设备Alexa区域',
              trigger: 'blur',
            },
          ],
          deviceType: [{ required: true, message: '请输入设备类型', trigger: 'blur' }],
          resolution: [{ required: true, message: '请输入分辨率', trigger: 'blur' }],
          aiType: [{ required: false, message: '请输入检测类型', trigger: 'blur' }],
          screenChannels: [{ required: true, message: '请输入屏幕通道数', trigger: 'blur' }],
          networkType: [{ required: true, message: '请输入网络类型', trigger: 'blur' }],
          otherFeatures: [{ required: false, message: '请输入其它功能', trigger: 'blur' }],
        },
        appList: [
          {
            value: 'ALL',
            label: 'ALL',
          },
          {
            value: 'US',
            label: 'US',
          },
          {
            value: 'EU',
            label: 'EU',
          },
          {
            value: 'CN',
            label: 'CN',
          },
        ],
        videoCodeList: [
          {
            value: 0,
            label: 'H.264',
          },
          {
            value: 1,
            label: 'H.265',
          },
        ],
        networkList: [
          {
            value: 4,
            label: '4G',
          },
          {
            value: 3,
            label: '无线+有线',
          },
          {
            value: 2,
            label: '有线',
          },
          {
            value: 1,
            label: '无线',
          },
        ],
      }
    },
    methods: {
      close() {
        this.$emit('update:visible', false)
      },
      handleSave() {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            const formData = new FormData()
            Object.keys(this.formData).forEach((key) => {
              if (this.formData[key] !== '' && this.formData[key] !== null) {
                formData.append(key, this.formData[key])
              }
            })
            if (this.formData.deviceVerId) {
              try {
                await editDeviceVerInfo(formData)
                this.$message.success('编辑成功')
                this.close()
                this.$emit('reloadData')
              } catch (error) {
                this.$message.error(error)
              }
            } else {
              try {
                await addDeviceVerInfo(formData)
                this.$message.success('添加成功')
                this.close()
                this.$emit('reloadData')
              } catch (error) {
                this.$message.error(error)
              }
            }
          } else {
            this.$message.error('请完善表单信息')
          }
        })
      },
      setFormData(deviceData) {
        // 转换布尔值为 1 或 0
        this.title = '编辑设备版本'
        this.isDisabled = true
        const transformedData = { ...deviceData }
        Object.keys(transformedData).forEach((key) => {
          if (typeof transformedData[key] === 'boolean') {
            transformedData[key] = transformedData[key] ? 1 : 0
          }
        })
        this.formData = { ...transformedData }
      },
      copySetFormData(deviceData) {
        // 转换布尔值为 1 或 0
        this.title = '新增设备版本'
        this.isDisabled = false
        const transformedData = { ...deviceData }
        Object.keys(transformedData).forEach((key) => {
          if (typeof transformedData[key] === 'boolean') {
            transformedData[key] = transformedData[key] ? 1 : 0
          }
        })
        this.formData = { ...transformedData }
      },
      resetFields() {
        this.title = '新增设备版本'
        this.isDisabled = false
        this.formData = { ...this.initialFormData }
      },
    },
  }
</script>
