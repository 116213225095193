var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "云存套餐转移",
        visible: _vm.dialogVisible,
        width: "350px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.transfer } },
                [_vm._v("转 移")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("el-input", {
        attrs: { clearable: "", placeholder: "请输入UID" },
        model: {
          value: _vm.form.new_uid,
          callback: function ($$v) {
            _vm.$set(
              _vm.form,
              "new_uid",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "form.new_uid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }