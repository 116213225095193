var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "echarts-container" },
    [
      _c(
        "el-card",
        { attrs: { shadow: "hover" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleOpen } },
                [
                  _c("vab-icon", {
                    staticClass: "vab-dropdown",
                    class: { "vab-dropdown-active": _vm.fold },
                    attrs: { icon: "arrow-up-s-line" },
                  }),
                  _vm._v(" 地区统计 "),
                ],
                1
              ),
              _c(
                "el-tooltip",
                { attrs: { placement: "top" } },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(" 选定时间内新增用户或活跃用户所在地区统计 "),
                    _c("br"),
                    _vm._v(" 注意：未知国家是由于当时并未统计特定国家 "),
                  ]),
                  _c("vab-icon", {
                    staticStyle: { color: "#409eff" },
                    attrs: { icon: "information-line" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.fold,
                  expression: "!fold",
                },
              ],
              staticStyle: { margin: "15px 0 0" },
              attrs: { height: _vm.height },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { lg: 8, md: 12, sm: 24, xl: 6, xs: 24 } },
                    [_c("chart-pie", { attrs: { data: _vm.data } })],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { lg: 16, md: 12, sm: 24, xl: 18, xs: 24 } },
                    [
                      _c("chart-table", {
                        attrs: {
                          "table-columns": _vm.tableColumns,
                          "table-data": _vm.data,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }