var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": true,
        title: "编辑套餐有效期",
        visible: _vm.dialogVisible,
        width: "400px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v(" 确 定 ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("el-date-picker", {
        attrs: {
          align: "center",
          placeholder: "选择日期",
          type: "date",
          "value-format": "timestamp",
        },
        model: {
          value: _vm.form.expireTime,
          callback: function ($$v) {
            _vm.$set(_vm.form, "expireTime", $$v)
          },
          expression: "form.expireTime",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }