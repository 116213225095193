var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.title === "添加",
              expression: "title === '添加'",
            },
          ],
          ref: "addForm",
          attrs: {
            "label-width": "100px",
            model: _vm.form,
            rules: _vm.addRules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "套餐", prop: "packageId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择套餐" },
                  model: {
                    value: _vm.form.packageId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "packageId", $$v)
                    },
                    expression: "form.packageId",
                  },
                },
                _vm._l(_vm.comboIdList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.combo_name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "数量", prop: "quantity" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.quantity,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "quantity",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.quantity",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.title === "编辑"
        ? _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "100px",
                model: _vm.form,
                rules: _vm.editRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择状态" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.statusList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否下载", prop: "isDown" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择状态" },
                      model: {
                        value: _vm.form.isDown,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isDown", $$v)
                        },
                        expression: "form.isDown",
                      },
                    },
                    _vm._l(_vm.isDownList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "过期时间", prop: "expireTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      placeholder: "选择日期时间",
                      type: "datetime",
                      "value-format": "timestamp",
                    },
                    model: {
                      value: _vm.form.expireTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "expireTime", $$v)
                      },
                      expression: "form.expireTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }