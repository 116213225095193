<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    top="3vh"
    :visible.sync="dialogFormVisible"
    width="650px"
    @close="close"
  >
    <el-form ref="form" v-loading="loading" label-position="left" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="活动名称" prop="activity_name">
        <el-input v-model="form.activity_name" placeholder="请输入活动名称" />
      </el-form-item>
      <div class="form-cell">
        <el-form-item label="活动期数" prop="issue" style="width: 48%">
          <el-input v-model="form.issue" placeholder="请输入活动期数" />
        </el-form-item>
        <el-form-item label="产品名" prop="product_name" style="width: 48%">
          <el-input v-model="form.product_name" placeholder="请输入产品名" />
        </el-form-item>
      </div>
      <div class="form-cell">
        <el-form-item label="物品数量" prop="product_number" style="width: 48%">
          <el-input v-model="form.product_number" placeholder="请输入物品数量" />
        </el-form-item>
        <el-form-item label="物品原价" prop="original_price" style="width: 48%">
          <el-input v-model="form.original_price" placeholder="请输入物品原价" />
        </el-form-item>
      </div>
      <div class="form-cell">
        <el-form-item label="轮播图" prop="carousel_image_url" style="width: 48%">
          <el-upload
            ref="uploadCarouselImage"
            action="#"
            :auto-upload="false"
            class="avatar-uploader"
            :http-request="upLoadCarouselImage"
            list-type="picture-card"
            :on-change="onCarouselImageChange"
            :show-file-list="false"
          >
            <el-image v-if="form.carousel_image_url" class="avatar" fit="contain" :src="form.carousel_image_url" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="详情图" prop="details_image_url" style="width: 48%">
          <el-upload
            ref="uploadDetailsImage"
            action="#"
            :auto-upload="false"
            class="avatar-uploader"
            :http-request="upLoadDetailsImage"
            :limit="1"
            list-type="picture-card"
            :on-change="onDetailsImageChange"
            :on-exceed="handleExceed"
            :show-file-list="false"
          >
            <el-image v-if="form.details_image_url" class="avatar" fit="contain" :src="form.details_image_url" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </div>
      <el-form-item class="activity-process" label="活动流程" prop="activity_process">
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in form.activity_process"
            :key="index"
            :icon="`ri-number-${index + 1}`"
            size="large"
          >
            <div>
              <span class="name">流程名称</span>
              <el-input v-model="activity.node_content" placeholder="请输入流程名称" />
            </div>
            <div>
              <span>日期</span>
              <el-date-picker
                v-model="activity.start_time"
                :default-time="['00:00:00', '23:59:59']"
                end-placeholder="结束日期"
                range-separator="-"
                start-placeholder="开始日期"
                type="daterange"
              />
              <div class="icon">
                <i
                  v-if="index === form.activity_process.length - 1 && form.activity_process.length > 1"
                  class="el-icon-remove-outline"
                  @click="deleteTimeline(index)"
                />
                <i
                  v-if="index === form.activity_process.length - 1 && form.activity_process.length < 5"
                  class="el-icon-circle-plus-outline"
                  @click="addTimeline(index)"
                />
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </el-form-item>
      <el-form-item label="是否显示" prop="is_show" style="width: 48%">
        <el-switch v-model="form.is_show" active-text="展示" :active-value="1" inactive-text="不展示" :inactive-value="0" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">保 存</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { addOrEditActivity } from '@/api/newProductExperience'

  export default {
    name: 'AddEditComponet',
    data() {
      const validateActivity = (rule, value, callback) => {
        // 遍历 activity_process 数组
        for (let i = 0; i < this.form.activity_process.length; i++) {
          let activity = this.form.activity_process[i]
          // 检查 node_content 和 start_time 是否为空
          if (activity.node_content === '' || activity.start_time === null) {
            // 如果发现任何一个为空，返回验证失败
            callback(new Error('请填写完整'))
          }
        }
        callback()
      }
      return {
        title: '',
        dialogFormVisible: false,
        loading: false,
        isEdit: false,
        carouselChanged: false,
        detailslChanged: false,
        form: {
          carousel_image_url: null,
          details_image_url: null,
          is_show: 0,
          activity_process: [
            {
              node_content: '免费招募',
              start_time: null,
              end_time: null,
            },
            {
              node_content: '内部选拔',
              start_time: null,
              end_time: null,
            },
            {
              node_content: '公布名单',
              start_time: null,
              end_time: null,
            },
            {
              node_content: '使用体验',
              start_time: null,
              end_time: null,
            },
            {
              node_content: '产出报告',
              start_time: null,
              end_time: null,
            },
          ],
        },
        rules: {
          activity_name: [{ required: true, trigger: 'blur', message: '请输入活动名称' }],
          issue: [{ required: true, trigger: 'blur', message: '请输入活动期数' }],
          product_name: [{ required: true, trigger: 'blur', message: '请输入产品名' }],
          product_number: [{ required: true, trigger: 'blur', message: '请输入物品数量' }],
          original_price: [{ required: true, trigger: 'blur', message: '请输入物品原价' }],
          carousel_image_url: [{ required: true, trigger: 'blur', message: '请上传轮播图' }],
          details_image_url: [{ required: true, trigger: 'blur', message: '请上传详情图' }],
          activity_process: [{ trigger: 'blur', validator: validateActivity }],
        },
      }
    },
    methods: {
      // 上传轮播图
      upLoadCarouselImage(file) {
        this.formData.append('carousel_image', file.file)
      },

      // 上传详情图
      upLoadDetailsImage(file) {
        this.formData.append('details_image', file.file)
      },

      onCarouselImageChange(file) {
        this.carouselChanged = true
        this.form.carousel_image_url = URL.createObjectURL(file.raw)
      },

      onDetailsImageChange(file) {
        this.detailslChanged = true
        this.form.details_image_url = URL.createObjectURL(file.raw)
      },

      handleExceed(files) {
        this.$refs.uploadDetailsImage.clearFiles()
        this.$nextTick(() => {
          this.$refs.uploadDetailsImage.handleStart(files[0])
        })
      },
      // 增加活动流程
      addTimeline(index) {
        this.$set(this.form.activity_process, index + 1, {
          node_content: '',
          start_time: null,
        })
      },
      // 减少活动流程
      deleteTimeline(index) {
        this.$delete(this.form.activity_process, index)
      },

      showComponent(row) {
        if (!row) {
          this.title = '添加'
          this.isEdit = false
        } else {
          this.title = '编辑'
          this.form = JSON.parse(JSON.stringify(row))
          this.form.activity_process.forEach((item) => {
            if (item.start_time !== null && item.end_time !== null) {
              item.start_time = [item.start_time * 1000, item.end_time * 1000]
            }
          })
          this.isEdit = true
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        this.carouselChanged = false
        this.detailslChanged = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.loading = true
            this.formData = new FormData()
            if (this.isEdit) {
              this.formData.append('activity_id', this.form.id)
            }
            this.formData.append('activity_name', this.form.activity_name)
            this.formData.append('issue', this.form.issue)
            this.formData.append('product_name', this.form.product_name)
            this.formData.append('product_number', this.form.product_number)
            this.formData.append('original_price', this.form.original_price)
            if (this.carouselChanged) {
              this.$refs.uploadCarouselImage.submit()
            }
            if (this.detailslChanged) {
              this.$refs.uploadDetailsImage.submit()
            }
            let activity_process = []
            for (let i = 0; i < this.form.activity_process.length; i++) {
              let newActivity = {
                node_content: this.form.activity_process[i].node_content,
                end_time: this.form.activity_process[i].start_time[1] / 1000,
                start_time: this.form.activity_process[i].start_time[0] / 1000,
              }
              activity_process.push(newActivity)
            }
            this.formData.append('activity_process', JSON.stringify(activity_process))
            this.formData.append('is_show', this.form.is_show)
            try {
              const { msg } = await addOrEditActivity(this.formData)
              this.loading = false
              this.$baseMessage(msg, 'success', 'vab-hey-message-success')
              this.$emit('fetch-data')
              this.close()
            } catch (error) {
              this.loading = false
              this.$baseMessage(error.message, 'error', 'vab-hey-message-error')
            }
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }
  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }
  .form-cell {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .el-timeline {
    padding: 0;
  }
  ::v-deep .el-timeline-item__node {
    background-color: $base-color-blue;
  }
  .activity-process {
    ::v-deep .el-form-item__content {
      margin-top: 10px;
    }
    .name {
      padding-bottom: 10px;
    }
    ::v-deep .el-input {
      width: 50%;
    }
    ::v-deep .el-range-editor {
      width: 50%;
    }
    ::v-deep .el-timeline-item__tail {
      border-left: 2px solid $base-color-blue;
    }
    ::v-deep .el-timeline-item__icon {
      font-size: 10px;
    }
    span {
      width: 65px;
      display: inline-flex;
    }
    .icon {
      position: absolute;
      top: 37%;
      padding-left: 10px;
      display: inline-block;
    }
    .el-icon-remove-outline {
      font-size: 25px;
      cursor: pointer;
    }
    .el-icon-circle-plus-outline {
      font-size: 25px;
      cursor: pointer;
    }
    .el-icon-circle-plus-outline:hover {
      color: $base-color-blue;
    }
    .el-icon-remove-outline:hover {
      color: $base-color-blue;
    }
  }
</style>
