<template>
  <div class="tabs">
    <el-card class="tabs-card" shadow="hover">
      <el-row
        class="row-bg"
        justify="center"
        style="margin: 0 0 15px"
        type="flex"
      >
        <el-col>
          <el-button v-if="title" type="primary" @click="handleFold">
            <vab-icon
              class="vab-dropdown"
              :class="{ 'vab-dropdown-active': !fold }"
              icon="arrow-up-s-line"
            />
            {{ title }}
          </el-button>
          <el-tooltip v-if="desc" placement="top">
            <div slot="content">{{ desc }}</div>
            <vab-icon icon="information-line" style="color: #409eff" />
          </el-tooltip>
        </el-col>
        <el-col style="display: flex; justify-content: right">
          <download-excel
            v-if="excelData"
            :before-finish="finishDownload"
            :before-generate="startDownload"
            class="export-excel-wrapper"
            :data="excelData"
            :fields="json_fields"
            :header="title"
            name="用户数据明细表.xls"
          >
            <el-button
              icon="el-icon-menu"
              :loading="buttonLoading"
              type="success"
            >
              导出
            </el-button>
          </download-excel>
        </el-col>
      </el-row>
      <el-table v-loading="loading" :data="tableData" :height="height">
        <el-table-column
          v-for="column in tableColumns"
          :key="column.prop"
          :label="column.label"
          :prop="column.prop"
        >
          <template slot-scope="scope">
            <slot :column="column" :scope="scope"></slot>
            <span v-if="column.label === '日期'">
              {{ scope.row[column.prop] | timeFormat }}
            </span>
            <span v-else-if="column.label === '用户数（百分比）'">
              {{ scope.row.count }} ({{ scope.row.rate }}%)
            </span>
            <span v-else-if="column.label === '新增用户（百分比）'">
              {{ scope.row.count }} ({{ scope.row.rate }}%)
            </span>
            <span v-else-if="column.label === '活跃用户（百分比）'">
              {{ scope.row.count }} ({{ scope.row.rate }}%)
            </span>
            <span v-else-if="column.label === '新增用户（占比）'">
              {{ scope.row.count }} ({{ scope.row.rate }}%)
            </span>
            <span v-else-if="column.label === '活跃用户（占比）'">
              {{ scope.row.aCount }} ({{ scope.row.acRate }}%)
            </span>
            <span v-else>
              {{ scope.row[column.prop] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
  export default {
    name: 'Table',
    filters: {
      timeFormat(val) {
        if (val === 0) {
          return 0
        } else {
          let date = new Date(val * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' '
          )
        }
      },
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      desc: {
        type: String,
        default: '',
      },
      excelData: {
        type: Array,
        default: null,
      },
      tableData: {
        type: Array,
        default: () => [],
      },
      tableColumns: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        loading: true,
        buttonLoading: false,
        fold: false,
        height: 260,
        json_fields: {
          日期: {
            field: 'startTime',
            callback: (value) => {
              return this.timesFormat(value)
            },
          },
          新增用户占比: 'count',
          活跃用户占比: 'aCount',
        },
      }
    },
    watch: {
      tableData() {
        this.loading = false
      },
    },
    methods: {
      handleFold() {
        this.fold = !this.fold
        if (this.fold) {
          this.height = 520
        } else {
          this.height = 260
        }
      },
      startDownload() {
        this.buttonLoading = true
      },
      finishDownload() {
        this.buttonLoading = false
      },
      timesFormat(val) {
        if (val === 0) {
          return 0
        } else {
          let date = new Date(val * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' '
          )
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-table {
      overflow: auto;
      &::before {
        height: 0px !important;
      }
    }
  }
</style>
