var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-col", { attrs: { lg: 24, md: 24, sm: 24, xl: 24, xs: 24 } }, [
    _c(
      "div",
      { staticClass: "tabs" },
      [
        _c(
          "el-card",
          { staticClass: "tabs-cards", attrs: { shadow: "hover" } },
          [
            _c(
              "el-row",
              {
                staticClass: "row-bg",
                attrs: { justify: "space-between", type: "flex" },
              },
              [
                _c(
                  "el-col",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleFold },
                      },
                      [
                        _c("vab-icon", {
                          staticClass: "vab-dropdown",
                          class: { "vab-dropdown-active": !_vm.fold },
                          attrs: { icon: "arrow-up-s-line" },
                        }),
                        _vm._v(" 设备数据明细表 "),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { placement: "top" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [_vm._v("提供所有应用在核心指标上的明细数据")]
                        ),
                        _c("vab-icon", {
                          staticStyle: { color: "#409eff" },
                          attrs: { icon: "information-line" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "download-excel",
                  {
                    staticClass: "export-excel-wrapper",
                    attrs: {
                      "before-finish": _vm.finishDownload,
                      "before-generate": _vm.startDownload,
                      data: _vm.excelData,
                      fields: _vm.json_fields,
                      header: _vm.title,
                      name: "设备数据明细表.xls",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-menu",
                          loading: _vm.buttonLoading,
                          type: "success",
                        },
                      },
                      [_vm._v(" 导出 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-tabs",
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    attrs: { data: _vm.queryData, height: _vm.height },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        formatter: _vm.timeFormat,
                        label: "日期",
                        prop: "startTime",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "新增设备（占比）", prop: "count,rate" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.count) +
                                  " (" +
                                  _vm._s(scope.row.rate) +
                                  "%) "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "活跃设备（占比）",
                        prop: "aCount,acRate",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.aCount) +
                                  " (" +
                                  _vm._s(scope.row.acRate) +
                                  "%) "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }