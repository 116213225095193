<template>
  <div class="domain-scan-log-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item>
            <el-select v-model="server" clearable placeholder="服务器">
              <el-option
                v-for="item in serverList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.serialNumber"
              clearable
              placeholder="序列号"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              :disabled="isDisabled"
              icon="el-icon-search"
              type="primary"
              @click="queryData"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
  </div>
</template>

<script>
  import { getDomainScanLog } from '@/api/domainScanLog'

  export default {
    name: 'ScanLog',
    data() {
      return {
        list: [],
        listLoading: false,
        columns: [
          {
            label: '序列号',
            width: 'auto',
            prop: 'serial_number',
            sortable: false,
          },
          {
            label: '域名IP',
            width: 'auto',
            prop: 'domain_ip',
            sortable: false,
            disableCheck: true,
          },
          {
            label: '地区ID',
            width: '100px',
            prop: 'region_id',
            sortable: false,
            disableCheck: true,
          },
          {
            label: 'api',
            width: 'auto',
            prop: 'api',
            sortable: false,
            disableCheck: true,
          },
          {
            label: 'api域名',
            width: '120px',
            prop: 'api_region',
            sortable: false,
            disableCheck: true,
          },
          {
            label: '获取域名记录添加时间',
            width: 'auto',
            prop: 'domain_add_time',
            sortable: false,
            disableCheck: true,
          },
          {
            label: '获取域名记录更新时间',
            width: 'auto',
            prop: 'domain_update_time',
            sortable: false,
            disableCheck: true,
          },
          {
            label: '扫码IP',
            width: 'auto',
            prop: 'scan_ip',
            sortable: false,
            disableCheck: true,
          },
          {
            label: '国家编码',
            width: '100px',
            prop: 'country_code',
            sortable: false,
            disableCheck: true,
          },
          {
            label: '扫码记录添加时间',
            width: 'auto',
            prop: 'scan_add_time',
            sortable: false,
            disableCheck: true,
          },
          {
            label: '扫码记录更新时间',
            width: 'auto',
            prop: 'scan_update_time',
            sortable: false,
            disableCheck: true,
          },
        ],
        queryForm: {
          serialNumber: '',
        },
        server: '',
        serverList: [
          {
            label: '国内服务器',
            value: 'https://www.zositechc.cn',
          },
          {
            label: '美洲服务器',
            value: 'http://129.153.85.188:8800',
          },
          {
            label: '欧洲服务器',
            value: 'http://34.247.5.236:8800',
          },
        ],
      }
    },
    computed: {
      isDisabled() {
        return !this.server || this.queryForm.serialNumber === ''
      },
    },
    methods: {
      queryData() {
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data } = await getDomainScanLog(this.server, this.queryForm)
        if (Object.keys(data).length != 0) {
          this.list = [data]
        } else {
          this.list = []
        }
        this.listLoading = false
      },
    },
  }
</script>
