var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "activity-management-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus", type: "primary" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v(" 新增 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { border: "", data: _vm.list },
        },
        [
          _vm._l(_vm.tableColumns, function (item) {
            return _c("el-table-column", {
              key: item.prop,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                "show-overflow-tooltip": item.toopltip,
                width: item.width,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        item.label === "轮播图"
                          ? _c(
                              "span",
                              [
                                _c("el-image", {
                                  attrs: {
                                    fit: "contain",
                                    "preview-src-list": [row[item.prop]],
                                    src: row[item.prop] + "?" + Date.now(),
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label === "详情图"
                          ? _c(
                              "span",
                              [
                                _c("el-image", {
                                  attrs: {
                                    fit: "contain",
                                    "preview-src-list": [row[item.prop]],
                                    src: row[item.prop] + "?" + Date.now(),
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label === "活动流程"
                          ? _c(
                              "span",
                              [
                                _c(
                                  "el-steps",
                                  {
                                    attrs: {
                                      active: row[item.prop].length,
                                      "align-center": "",
                                    },
                                  },
                                  _vm._l(
                                    row[item.prop],
                                    function (step, index) {
                                      return _c("el-step", {
                                        key: index,
                                        attrs: {
                                          description: _vm.computedDesc(
                                            step.start_time,
                                            step.end_time
                                          ),
                                          title: step.node_content,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : item.label === "是否展示"
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("typeFilter")(
                                      row[item.prop],
                                      _vm.showStatusList
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("span", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDetail(row)
                          },
                        },
                      },
                      [_vm._v(" 报名详情 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.page,
          layout: _vm.layout,
          "page-size": _vm.queryForm.line,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } }),
      _c("Details", { ref: "details" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }