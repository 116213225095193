var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-col",
    [
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "18px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _c(
                    "el-tooltip",
                    { attrs: { placement: "top" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v("展示排行前20位的国家数据"),
                          _c("br"),
                          _vm._v("注意：未知国家是由于当时并未统计特定国家"),
                        ]
                      ),
                      _c("vab-icon", { attrs: { icon: "information-line" } }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("vab-chart", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { height: "470px" },
            attrs: {
              "init-options": _vm.initOptions,
              option: _vm.option,
              theme: "vab-echarts-theme",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }