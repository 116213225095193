var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs" },
    [
      _c(
        "el-col",
        { attrs: { lg: 24, md: 24, sm: 24, xl: 24, xs: 24 } },
        [
          _c(
            "el-card",
            { staticClass: "tabs-card", attrs: { shadow: "hover" } },
            [
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { justify: "space-between", type: "flex" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleFold },
                    },
                    [
                      _c("vab-icon", {
                        staticClass: "vab-dropdown",
                        class: { "vab-dropdown-active": !_vm.fold },
                        attrs: { icon: "arrow-up-s-line" },
                      }),
                      _vm._v(" 设备类型明细表 "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tabs",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: { data: _vm.tableData, height: _vm.height },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "设备名", prop: "type" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "类型总数（百分比）",
                          prop: "count,rate",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.count) +
                                    " (" +
                                    _vm._s(scope.row.rate) +
                                    "%) "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }