var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            [
              _c(
                "el-card",
                { staticClass: "order-card1", attrs: { shadow: "hover" } },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "font-size": "20px",
                        "font-weight": "600",
                      },
                    },
                    [
                      _c("vab-icon", {
                        staticStyle: {
                          "font-size": "30px",
                          "font-weight": "500",
                        },
                        attrs: { icon: "heart-pulse-line" },
                      }),
                      _vm._v(" 平台活跃用户数 "),
                      _c(
                        "el-tooltip",
                        { attrs: { placement: "top" } },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "启动过应用的用户，启动过一次的用户即视为活跃用户，"
                              ),
                              _c("br"),
                              _vm._v("包括新用户与老用户"),
                            ]
                          ),
                          _c("vab-icon", {
                            attrs: { icon: "information-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "font-size": "17px",
                        "font-weight": "500",
                        float: "right",
                        "margin-right": "100px",
                      },
                    },
                    [
                      _c(
                        "p",
                        [
                          _vm._v(" 昨日 "),
                          _c("vab-count", {
                            staticStyle: {
                              "font-size": "30px",
                              "font-weight": "600",
                            },
                            attrs: {
                              decimals: _vm.countConfig.decimals,
                              duration: _vm.countConfig.duration,
                              "end-val": _vm.countConfig.endVal,
                              prefix: _vm.countConfig.prefix,
                              separator: _vm.countConfig.separator,
                              "start-val": _vm.countConfig.startVal,
                              suffix: _vm.countConfig.suffix,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }