<template>
  <div class="device-ver-info-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-button v-if="$perms('version_deviceVerInfo_insert')" icon="el-icon-plus" type="primary" @click="handleAdd">
              添加
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent="fetchData">
          <el-form-item>
            <el-input v-model.trim="queryForm.dCode" clearable placeholder="设备规格码" />
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="queryForm.softwareVer" clearable placeholder="设备版本" />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="fetchData">查询</el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" fixed="left" label="设备规格码" prop="dCode" show-overflow-tooltip width="140" />
      <el-table-column align="center" fixed="left" label="设备版本" prop="softwareVer" show-overflow-tooltip />
      <el-table-column align="center" label="固件版本" prop="firmwareVer" show-overflow-tooltip />
      <el-table-column align="center" :formatter="videoCodeFormat" label="编码类型" prop="videoCode" show-overflow-tooltip />
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="是否支持人形追踪"
        prop="supportsHumanTracking"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="是否支持自定义语音"
        prop="supportsCustomVoice"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="是否支持双频Wifi"
        prop="supportsDualBandWifi"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="是否支持预置点"
        prop="supportsFourPoint"
        show-overflow-tooltip
      />
      <el-table-column align="center" :formatter="statusFormat" label="是否支持4G" prop="supports4g" show-overflow-tooltip />
      <el-table-column align="center" :formatter="statusFormat" label="是否支持云台" prop="supportsPTZ" show-overflow-tooltip />
      <el-table-column align="center" :formatter="statusFormat" label="是否支持AI" prop="supportsAi" show-overflow-tooltip />
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="是否支持云存储"
        prop="supportsCloudStorage"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="是否支持Alexa"
        prop="supportsAlexa"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="设备Alexa区域" prop="regionAlexa" show-overflow-tooltip />
      <el-table-column align="center" :formatter="statusFormat" label="设备类型" prop="deviceType" show-overflow-tooltip />
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="分辨率"
        prop="resolution"
        show-overflow-tooltip
        width="140"
      />
      <el-table-column align="center" :formatter="statusFormat" label="检测类型" prop="aiType" show-overflow-tooltip />
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="是否支持声光报警"
        prop="supportsAlarm"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="是否支持夜视模式"
        prop="supportsNightVision"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="屏幕通道数" prop="screenChannels" show-overflow-tooltip />
      <el-table-column align="center" :formatter="netTypeFormat" label="网络类型" prop="networkType" show-overflow-tooltip />
      <el-table-column align="center" label="其它功能" prop="otherFeatures" show-overflow-tooltip />
      <el-table-column align="center" :formatter="timeFormat" label="创建时间" prop="createdTime" show-overflow-tooltip />
      <el-table-column align="center" :formatter="timeFormat" label="更新时间" prop="updatedTime" show-overflow-tooltip />
      <el-table-column align="center" fixed="right" label="操作" width="225">
        <template #default="{ row }">
          <el-button v-if="$perms('version_deviceVerInfo_edit')" type="primary" @click="handleEdit(row)">编辑</el-button>
          <el-button v-if="$perms('version_deviceVerInfo_delete')" type="danger" @click="handleDelete(row)">删除</el-button>
          <el-button v-if="$perms('version_deviceVerInfo_copyAdd')" type="primary" @click="copyAdd(row)">复制</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />

    <add-device-ver-info
      ref="deviceVerInfo"
      :title="title"
      :visible="showAddDialog"
      @reloadData="reloadTableData"
      @update:visible="showAddDialog = $event"
    />
  </div>
</template>

<script>
  import { getDeviceVerInfo, delDeviceVerInfo } from '@/api/deviceVerInfo'
  import { formatDate } from '@/utils/formatDate'
  import AddDeviceVerInfo from './components/AddDeviceVerInfo'

  export default {
    name: 'DeviceVerInfo',
    components: {
      AddDeviceVerInfo, // 注册组件
    },
    data() {
      return {
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        list: [],
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        showAddDialog: false, // 控制弹窗的显示
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      handleCurrentChange(pageNumber) {
        this.queryForm.pageNo = pageNumber
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getDeviceVerInfo(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      timeFormat(row, column) {
        const data = row[column.property]
        return formatDate(new Date(data * 1000), 'yyyy-MM-dd hh:mm:ss')
      },
      statusFormat(row, column) {
        const data = row[column.property]
        if (typeof data === 'boolean') {
          return data ? '支持' : '不支持'
        }
        if (column.property === 'deviceType') {
          return data ? data : '未知设备类型'
        }
        if (column.property === 'resolution') {
          return data ? data : '未知分辨率'
        }
        return data || '-' // 默认返回数据或 '-'
      },
      netTypeFormat(row, column) {
        const data = row[column.property]
        if (data === 4) {
          return '4G'
        }
        if (data === 3) {
          return '无线+有线'
        }
        if (data === 2) {
          return '有线'
        }
        if (data === 1) {
          return '无线'
        }
        return '未知网络类型'
      },
      videoCodeFormat(row, column) {
        const data = row[column.property]
        if (data === 0) {
          return 'H.264'
        }
        if (data === 1) {
          return 'H.265'
        }
        return '未知编码类型'
      },
      handleSizeChange(pageSize) {
        this.queryForm.pageSize = pageSize
        this.fetchData()
      },
      handleAdd() {
        this.$nextTick(() => {
          this.$refs.deviceVerInfo.resetFields()
        })
        this.showAddDialog = true
      },
      handleEdit(row) {
        this.$nextTick(() => {
          this.$refs.deviceVerInfo.setFormData(row)
        })
        this.showAddDialog = true // 显示弹窗
      },
      copyAdd(row) {
        this.$nextTick(() => {
          delete row.deviceVerId // 去掉主键
          this.$refs.deviceVerInfo.copySetFormData(row)
        })
        this.showAddDialog = true
      },
      handleDelete(row) {
        if (row.deviceVerId) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const formData = new FormData()
            formData.append('deviceVerId', row.deviceVerId)
            try {
              const { msg } = await delDeviceVerInfo(formData)
              this.$baseMessage(msg, 'success', 'vab-hey-message-success')
              await this.fetchData()
            } catch (error) {
              console.error('Error deleting device:', error)
            }
          })
        }
      },
      async reloadTableData() {
        await this.fetchData()
      },
    },
  }
</script>
