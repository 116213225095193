var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "100px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "套餐名称", prop: "comboName" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  maxlength: "30",
                  placeholder: "请输入套餐名称",
                },
                model: {
                  value: _vm.form.comboName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "comboName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.comboName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "套餐类型", prop: "comboType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择套餐类型" },
                  model: {
                    value: _vm.form.comboType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "comboType", $$v)
                    },
                    expression: "form.comboType",
                  },
                },
                _vm._l(_vm.comboList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "流量总量值", prop: "flowTotal" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  placeholder: "请输入流量总量值，单位为MB",
                },
                model: {
                  value: _vm.form.flowTotal,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "flowTotal",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.flowTotal",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "有效期天数", prop: "expirationDays" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入有效期天数" },
                model: {
                  value: _vm.form.expirationDays,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "expirationDays",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.expirationDays",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "有效期类型", prop: "expirationType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择有效期类型" },
                  model: {
                    value: _vm.form.expirationType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "expirationType", $$v)
                    },
                    expression: "form.expirationType",
                  },
                },
                _vm._l(_vm.expirationTypeList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "付款类型", prop: "payTypes" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    multiple: "",
                    placeholder: "请选择付款类型(可多选)",
                  },
                  model: {
                    value: _vm.form.payTypes,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "payTypes", $$v)
                    },
                    expression: "form.payTypes",
                  },
                },
                _vm._l(_vm.payTypeList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "价格", prop: "price" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入价格" },
                on: {
                  change: function ($event) {
                    return _vm.checkValue()
                  },
                },
                model: {
                  value: _vm.form.price,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "price",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.price",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "原价格", prop: "virtualPrice" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入原价格" },
                on: {
                  change: function ($event) {
                    return _vm.checkVirtualPrice()
                  },
                },
                model: {
                  value: _vm.form.virtualPrice,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "virtualPrice",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.virtualPrice",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "描述信息", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入描述信息" },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "remark",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否显示", prop: "isShow" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择是否显示" },
                  model: {
                    value: _vm.form.isShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isShow", $$v)
                    },
                    expression: "form.isShow",
                  },
                },
                _vm._l(_vm.isShowList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sort" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入排序值" },
                model: {
                  value: _vm.form.sort,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "sort",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.sort",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }