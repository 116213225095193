<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form
      ref="form"
      label-position="left"
      label-width="80px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="用户名" prop="username">
        <el-input
          v-model="form.username"
          clearable
          placeholder="请输入用户名"
        />
      </el-form-item>
      <el-form-item label="UID" prop="uid">
        <el-input v-model="form.uid" clearable placeholder="请输入UID" />
      </el-form-item>
      <el-form-item label="通道" prop="channel">
        <el-input v-model="form.channel" clearable placeholder="请输入通道" />
      </el-form-item>
      <el-form-item label="激活码" prop="cdk">
        <el-input v-model="form.cdk" clearable placeholder="请输入激活码" />
      </el-form-item>
      <el-form-item label="语言" prop="lang">
        <el-select v-model="form.lang" clearable placeholder="请选择语言">
          <el-option
            v-for="item in languageList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">保 存</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { experienceorder } from '@/api/cdk'
  import { getToken } from '@/utils/token'
  import { Loading } from 'element-ui'

  export default {
    name: 'AddEditComponet',
    data() {
      return {
        title: '设备云存兑换',
        dialogFormVisible: false,
        form: {},
        rules: {
          uid: [{ required: true, trigger: 'blur', message: '请输入UID' }],
          username: [
            { required: true, trigger: 'blur', message: '请输入用户名' },
          ],
          channel: [{ required: true, trigger: 'blur', message: '请输入通道' }],
          cdk: [{ required: true, trigger: 'blur', message: '请输入激活码' }],
          lang: [{ required: true, trigger: 'blur', message: '请选择语言' }],
        },
        languageList: [
          {
            label: '简体中文',
            value: 'cn',
          },
          {
            label: '繁体中文',
            value: 'cn_tw',
          },
          {
            label: '英文',
            value: 'en',
          },
          {
            label: '日语',
            value: 'ja',
          },
          {
            label: '俄语',
            value: 'ru',
          },
          {
            label: '葡萄牙语',
            value: 'pt',
          },
          {
            label: '波兰语',
            value: 'pl',
          },
          {
            label: '荷兰语',
            value: 'nl',
          },
          {
            label: '意大利语',
            value: 'it',
          },
          {
            label: '法语',
            value: 'fr',
          },
          {
            label: '西班牙语',
            value: 'es',
          },
          {
            label: '德语',
            value: 'de',
          },
        ],
      }
    },
    methods: {
      showComponent() {
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const token = getToken()
            this.formData = new FormData()
            this.formData.append('pay_type', 11)
            this.formData.append('uid', this.form.uid)
            this.formData.append('username', this.form.username)
            this.formData.append('channel', this.form.channel)
            this.formData.append('cdk', this.form.cdk)
            this.formData.append('lang', this.form.lang)
            this.formData.append('token', token)

            let loadingInstance = Loading.service({
              lock: true,
              text: '设备云存兑换中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
            })

            try {
              const { error_code, reason } = await experienceorder(
                this.formData
              )
              if (error_code === 0) {
                this.$baseMessage(reason, 'success', 'vab-hey-message-success')
                this.$emit('fetch-data')
                this.close()
              } else {
                this.$baseMessage(reason, 'error', 'vab-hey-message-success')
              }
            } catch (error) {
              console.error('API call failed:', error)
              this.$baseMessage(
                '请求失败，请稍后重试。',
                'error',
                'vab-hey-message-success'
              )
            } finally {
              loadingInstance.close()
            }
          }
        })
      },
    },
  }
</script>
