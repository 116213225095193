var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "100px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "APP名称", prop: "appName" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.appName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "appName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.appName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "APP ID", prop: "appBundleId" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.appBundleId,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "appBundleId",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.appBundleId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "项目版本", prop: "bundleVersion" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.bundleVersion,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "bundleVersion",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.bundleVersion",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "系统版本", prop: "newAppversion" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.newAppversion,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "newAppversion",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.newAppversion",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "最小系统版本", prop: "minAppversion" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.minAppversion,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "minAppversion",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.minAppversion",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新内容", prop: "content" } },
            [
              _c("el-input", {
                attrs: { clearable: "", rows: 2, type: "textarea" },
                model: {
                  value: _vm.form.content,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "content",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "APP类型", prop: "app_type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择APP类型" },
                  model: {
                    value: _vm.form.app_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "app_type", $$v)
                    },
                    expression: "form.app_type",
                  },
                },
                _vm._l(_vm.appTypeList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下载路径", prop: "downloadLink" } },
            [
              _c("el-input", {
                attrs: { clearable: "", rows: 2, type: "textarea" },
                model: {
                  value: _vm.form.downloadLink,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "downloadLink",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.downloadLink",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }