import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/deviceManagement/getDeviceVoice',
    method: 'get',
    params,
  })
}

export function getTypeList(params) {
  return request({
    url: '/deviceManagement/algorithmTypeList',
    method: 'get',
    params,
  })
}

export function add(data) {
  return request({
    url: '/deviceManagement/addDeviceVoice',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}

export function edit(data) {
  return request({
    url: '/deviceManagement/editDeviceVoice',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}

export function deleteItem(data) {
  return request({
    url: '/deviceManagement/delDeviceVoice',
    method: 'post',
    data,
  })
}

export function getDeviceTypeList(params) {
  return request({
    url: '/deviceManagement/deviceTypeList',
    method: 'get',
    params,
  })
}
