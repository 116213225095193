var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "serial-traffic-package-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticStyle: { width: "auto" } },
            [
              _c(
                "el-card",
                { attrs: { shadow: "hover" } },
                [
                  _c(
                    "el-row",
                    { staticClass: "option" },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "upload",
                          attrs: {
                            accept: ".txt",
                            action: "string",
                            "auto-upload": false,
                            "file-list": _vm.fileList,
                            "http-request": _vm.upLoadFile,
                            limit: 1,
                            "on-exceed": _vm.handleExceed,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "upload_btn",
                              staticStyle: { margin: "0 10px 0 0 !important" },
                              attrs: { slot: "trigger", type: "primary" },
                              slot: "trigger",
                            },
                            [_vm._v(" 选择序列号文件 ")]
                          ),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "请选择套餐",
                              },
                              model: {
                                value: _vm.form.packageId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "packageId", $$v)
                                },
                                expression: "form.packageId",
                              },
                            },
                            _vm._l(_vm.comboIdList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.combo_name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                          _vm.$perms("iot_serialTrafficPackage_upload")
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    margin: "0px 0 0 10px !important",
                                  },
                                  attrs: {
                                    icon: "el-icon-plus",
                                    type: "primary",
                                  },
                                  on: { click: _vm.handleUpload },
                                },
                                [_vm._v(" 上传 ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.amount || _vm.amount === 0
                    ? _c("el-row", { staticClass: "amount" }, [
                        _c("span", [
                          _vm._v("已上传" + _vm._s(_vm.amount) + "条"),
                        ]),
                      ])
                    : _vm._e(),
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { rows: 10, type: "textarea" },
                    model: {
                      value: _vm.textarea,
                      callback: function ($$v) {
                        _vm.textarea = $$v
                      },
                      expression: "textarea",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 24, md: 24, sm: 24, xl: 24, xs: 24 } },
            [
              _c(
                "el-card",
                { attrs: { shadow: "hover" } },
                [
                  _c(
                    "vab-query-form",
                    [
                      _c(
                        "vab-query-form-left-panel",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form",
                            {
                              staticClass: "queryForm",
                              attrs: { inline: true, model: _vm.queryForm },
                              nativeOn: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入设备唯一序列号",
                                    },
                                    model: {
                                      value: _vm.queryForm.serialNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryForm,
                                          "serialNumber",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "queryForm.serialNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择套餐",
                                      },
                                      model: {
                                        value: _vm.queryForm.packageId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryForm,
                                            "packageId",
                                            $$v
                                          )
                                        },
                                        expression: "queryForm.packageId",
                                      },
                                    },
                                    _vm._l(_vm.comboIdList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.combo_name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-search",
                                        type: "primary",
                                      },
                                      on: { click: _vm.fetchData },
                                    },
                                    [_vm._v(" 查询 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vab-query-form-right-panel",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form",
                            { staticClass: "file_deal" },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "uploadPlus",
                                      staticClass: "upload",
                                      attrs: {
                                        accept: ".txt",
                                        action: "string",
                                        "auto-upload": false,
                                        "file-list": _vm.fileListPlus,
                                        "http-request": _vm.upLoadFilePlus,
                                        limit: 1,
                                        "on-exceed": _vm.handleExceedPlus,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            slot: "trigger",
                                            icon: "el-icon-plus",
                                            type: "primary",
                                          },
                                          slot: "trigger",
                                        },
                                        [_vm._v(" 选择文件 ")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            icon: "el-icon-document-checked",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkAndDownload(1)
                                            },
                                          },
                                        },
                                        [_vm._v(" 核对 ")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            icon: "el-icon-menu",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkAndDownload(2)
                                            },
                                          },
                                        },
                                        [_vm._v(" 导出 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      attrs: { border: "", data: _vm.list },
                      on: { "selection-change": _vm.setSelectRows },
                      scopedSlots: _vm._u([
                        {
                          key: "empty",
                          fn: function () {
                            return [
                              _c("el-image", {
                                staticClass: "vab-data-empty",
                                attrs: {
                                  src: require("@/assets/empty_images/data_empty.png"),
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "设备唯一序列号",
                          prop: "serialNumber",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "套餐id",
                          prop: "packageId",
                          "show-overflow-tooltip": "",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "套餐名称",
                          prop: "packageName",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          formatter: _vm.statusFormat,
                          label: "状态",
                          prop: "status",
                          "show-overflow-tooltip": "",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          formatter: _vm.timeFormat,
                          label: "创建时间",
                          prop: "createdTime",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "创建人",
                          prop: "createdBy",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          formatter: _vm.timeFormat,
                          label: "修改时间",
                          prop: "updatedTime",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "修改人",
                          prop: "updatedBy",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "操作", width: "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { disabled: "", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 编辑 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.queryForm.pageNo,
                      layout: _vm.layout,
                      "page-size": _vm.queryForm.pageSize,
                      total: _vm.total,
                    },
                    on: {
                      "current-change": _vm.handleCurrentChange,
                      "size-change": _vm.handleSizeChange,
                    },
                  }),
                  _c("Edit", {
                    ref: "edit",
                    attrs: { "combo-id-list": _vm.comboIdList },
                    on: { "fetch-data": _vm.fetchData },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Detail", { ref: "detail" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }