<template>
  <el-card shadow="hover">
    <el-row class="row-bg" justify="space-between" type="flex">
      <el-radio-group v-model="radio1" @change="buttonChange">
        <el-radio-button :label="1">国家</el-radio-button>
        <el-radio-button :label="2">大洲</el-radio-button>
      </el-radio-group>
    </el-row>
    <template #header>
      <span style="font-weight: bold; font-size: 18px">{{ title }}</span>
      <el-tooltip placement="top">
        <div slot="content">
          展示排行前20位的国家数据
          <br />
          注意：未知国家和未知大洲是由于当时并未统计特定的国家和大洲
        </div>
        <vab-icon icon="information-line" />
      </el-tooltip>
    </template>
    <vab-chart
      v-loading="loading"
      :init-options="initOptions"
      :option="option"
      style="height: 400px"
      theme="vab-echarts-theme"
    />
  </el-card>
</template>

<script>
  import VabChart from '@/extra/VabChart'
  export default {
    name: 'VabChartBar',
    components: {
      VabChart,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      data: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        loading: true,
        radio1: 1,
        initOptions: {
          renderer: 'svg',
        },
        option: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          dataZoom: [
            {
              type: 'inside',
            },
          ],
          grid: {
            top: '5%',
            left: '2%',
            right: '4%',
            bottom: '0%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            data: [],
          },
          yAxis: [
            {
              type: 'value',
            },
          ],
          series: {
            type: 'bar',
            itemStyle: {
              color: new VabChart.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' },
              ]),
            },
            emphasis: {
              itemStyle: {
                color: new VabChart.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' },
                ]),
              },
            },
            data: [],
          },
        },
      }
    },
    watch: {
      data(newVal) {
        this.getData(newVal)
      },
    },
    methods: {
      getData(data) {
        let names = []
        let counts = []

        if (this.radio1 === 1) {
          // 处理国家数据
          names = data.map((country) => country.countryName)
          counts = data.map((country) => country.count)
        } else {
          // 处理大陆数据
          names = data.map((continent) => continent.continentName)
          counts = data.map((continent) => continent.count)
        }

        this.option.xAxis.data = names
        this.option.series.data = counts
        this.loading = false
      },
      buttonChange(val) {
        if (val == 1) {
          this.$emit('regionType', 'countries')
        } else {
          this.$emit('regionType', 'continent')
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-card {
      height: 500px !important;
    }
  }
</style>
