var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "echarts-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("data-picker"),
          _c("chart-line", { attrs: { title: "新增趋势" } }),
          _c("chart-collection"),
          _c("chart-collectiona"),
          _c("chart-pieb"),
          _c("chart-piec"),
          _c("chart-pied"),
          _c("chart-piee"),
          _c("chart-tableb"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }