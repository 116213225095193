var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保 存")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "left",
            "label-width": "80px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "详情", prop: "msg" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.form.msg,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "msg", $$v)
                  },
                  expression: "form.msg",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "链接", prop: "link" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.link,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "link", $$v)
                  },
                  expression: "form.link",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "预览图", prop: "icon" } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-file",
                  attrs: {
                    action: "#",
                    "auto-upload": false,
                    "http-request": _vm.upLoadFile,
                    "list-type": "picture-card",
                    "on-change": _vm.onChange,
                    "on-remove": _vm.onRemove,
                    "show-file-list": false,
                  },
                },
                [
                  _vm.form.icon_link
                    ? _c("img", { attrs: { src: _vm.form.icon_link } })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "国家", prop: "country" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    multiple: "",
                    placeholder: "请选择国家",
                  },
                  model: {
                    value: _vm.form.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "country", $$v)
                    },
                    expression: "form.country",
                  },
                },
                _vm._l(_vm.countryList, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "设备型号", prop: "device_type" } },
            [
              _c("Select", {
                attrs: {
                  "mul-select-loading": _vm.deviceTypeListLoading,
                  "mul-selecteds": _vm.form.device_type,
                  "select-options": _vm.deviceTypeList,
                },
                on: { updateMultipleSelect: _vm.updateMultipleSelect },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "re_period",
              attrs: { label: "注册年限", prop: "register_period" },
            },
            [
              _c("el-row", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.form.register_period[0]) +
                      " - " +
                      _vm._s(
                        _vm.form.register_period[1] === 6
                          ? "5年以上"
                          : _vm.form.register_period[1] + "年"
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "el-row",
                [
                  _c("el-slider", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      marks: _vm.marks,
                      max: 6,
                      range: "",
                      "show-stops": "",
                      "show-tooltip": false,
                    },
                    model: {
                      value: _vm.form.register_period,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "register_period", $$v)
                      },
                      expression: "form.register_period",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "推送时间", prop: "time" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "145px" },
                      attrs: { placeholder: "时区" },
                      model: {
                        value: _vm.form.time_zone,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "time_zone", $$v)
                        },
                        expression: "form.time_zone",
                      },
                    },
                    _vm._l(_vm.timeZones, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      placeholder: "请选择时间",
                      type: "datetime",
                      "value-format": "timestamp",
                    },
                    model: {
                      value: _vm.form.push_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "push_time", $$v)
                      },
                      expression: "form.push_time",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "推送APP", prop: "push_app" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "周视" },
                  model: {
                    value: _vm.form.push_app,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "push_app", $$v)
                    },
                    expression: "form.push_app",
                  },
                },
                [_vm._v("周视")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "ZosiSmart" },
                  model: {
                    value: _vm.form.push_app,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "push_app", $$v)
                    },
                    expression: "form.push_app",
                  },
                },
                [_vm._v("ZosiSmart")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }