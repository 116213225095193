var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            action: "http://127.0.0.1:8001/testServe/AItest",
            data: "fileData",
            "file-list": _vm.fileList,
            "list-type": "picture",
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
          },
        },
        [
          _c("el-button", { attrs: { size: "small", type: "primary" } }, [
            _vm._v("点击上传"),
          ]),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v(" 图片必须是.jpeg 或 .png 格式，不得大于5MB ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }