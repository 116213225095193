var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-management-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "上传uid", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.dialogForm } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "企业名称:",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.dialogForm.companyName))])]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "区域名称:",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.dialogForm.regionName))])]
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "族群:", "label-width": _vm.formLabelWidth },
                },
                [_c("span", [_vm._v(_vm._s(_vm.dialogForm.name))])]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "uid总数:",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.dialogForm.uidCount))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "p2p类型:" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { clearable: "", placeholder: "请选择p2p类型" },
                      on: { change: _vm.p2pTypeChange },
                      model: {
                        value: _vm.dialogForm.p2pType,
                        callback: function ($$v) {
                          _vm.$set(_vm.dialogForm, "p2pType", $$v)
                        },
                        expression: "dialogForm.p2pType",
                      },
                    },
                    _vm._l(_vm.p2pTypeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.showInput
                ? _c(
                    "el-form-item",
                    { attrs: { label: "*平台:" } },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "nameSel",
                          staticStyle: { width: "150px" },
                          attrs: {
                            clearable: "",
                            filterable: "",
                            "allow-create": "",
                            "default-first-option": "",
                            placeholder: "请选择平台",
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.nameblur()
                            },
                          },
                          model: {
                            value: _vm.dialogForm.platform,
                            callback: function ($$v) {
                              _vm.$set(_vm.dialogForm, "platform", $$v)
                            },
                            expression: "dialogForm.platform",
                          },
                        },
                        _vm._l(_vm.platformList, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showInput
                ? _c(
                    "el-form-item",
                    { attrs: { label: "*设备初始化字符:" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入设备初始化字符",
                        },
                        model: {
                          value: _vm.dialogForm.initString,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialogForm, "initString", $$v)
                          },
                          expression: "dialogForm.initString",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showInput
                ? _c(
                    "el-form-item",
                    { attrs: { label: "*app初始化字符:" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入app初始化字符",
                        },
                        model: {
                          value: _vm.dialogForm.initStringApp,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialogForm, "initStringApp", $$v)
                          },
                          expression: "dialogForm.initStringApp",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload-file",
                      attrs: {
                        action: "",
                        "auto-upload": false,
                        "on-change": _vm.changeUpload,
                      },
                    },
                    [
                      _vm.$perms("device_devicereset_select")
                        ? _c(
                            "el-button",
                            {
                              attrs: { slot: "trigger", type: "primary" },
                              slot: "trigger",
                            },
                            [_vm._v(" 选取uid文件 ")]
                          )
                        : _vm._e(),
                      _vm.$perms("device_devicereset_select")
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { type: "success" },
                              on: { click: _vm.submitUpload },
                            },
                            [_vm._v(" 开始上传 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { border: "", data: _vm.list },
          on: { "selection-change": _vm.setSelectRows },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          false
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "id",
                  prop: "id",
                  "show-overflow-tooltip": "",
                },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "55" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ $index }) {
                  return [_vm._v(" " + _vm._s($index + 1) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "id",
              prop: "id",
              "show-overflow-tooltip": "",
              width: "55",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "企业名称",
              prop: "company__name",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "区域名称",
              prop: "region__name",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "族群",
              prop: "name",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "uid总数",
              prop: "uid_count",
              "show-overflow-tooltip": "",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "tutk已使用uid数量",
              prop: "tutk_use_count",
              "show-overflow-tooltip": "",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "尚云已使用uid数量",
              prop: "shangyun_use_count",
              "show-overflow-tooltip": "",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "tutk未使用uid数量",
              prop: "tutk_unuse_count",
              "show-overflow-tooltip": "",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "尚云未使用uid数量",
              prop: "shangyun_unuse_count",
              "show-overflow-tooltip": "",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "show-overflow-tooltip": "",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm.$perms("vpg_info_uploaduid")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpload(row)
                              },
                            },
                          },
                          [_vm._v(" 上传uid ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }