var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户名", prop: "username" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.username,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "username",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.username",
                },
              }),
            ],
            1
          ),
          _vm.title == "添加"
            ? _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "password",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.password",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "邮箱", prop: "userEmail" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.userEmail,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "userEmail",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.userEmail",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户角色：", prop: "role" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择角色" },
                  model: {
                    value: _vm.form.role,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "role", $$v)
                    },
                    expression: "form.role",
                  },
                },
                _vm._l(_vm.roleList, function (item) {
                  return _c("el-option", {
                    key: item.rid,
                    attrs: { label: item.roleName, value: item.roleName },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.role === "代理商"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "代理公司", prop: "agent_company_name" },
                    },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.form.agent_company_name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "agent_company_name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.agent_company_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "代理人", prop: "card_name" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.form.card_name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "card_name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.card_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话号码", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "phone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "卡号", prop: "card_no" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.form.card_no,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "card_no",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.card_no",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "银行名称", prop: "card_address" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.form.card_address,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "card_address",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.card_address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }