var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      attrs: {
        clearable: "",
        disabled: _vm.disabled,
        filterable: "",
        loading: _vm.mulSelectLoading,
        multiple: "",
        placeholder: "请选择设备型号",
      },
      on: { change: _vm.changeSelect },
      model: {
        value: _vm.selectedArr,
        callback: function ($$v) {
          _vm.selectedArr = $$v
        },
        expression: "selectedArr",
      },
    },
    [
      _c(
        "el-checkbox",
        {
          on: { change: _vm.selectAll },
          model: {
            value: _vm.checked,
            callback: function ($$v) {
              _vm.checked = $$v
            },
            expression: "checked",
          },
        },
        [_vm._v("全选")]
      ),
      _vm._l(_vm.selectOptions, function (item) {
        return _c("el-option", {
          key: item,
          attrs: { label: item, value: item },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }