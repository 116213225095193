<template>
  <div class="personalized-voice-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="4">
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">新增</el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="20">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent="fetchData">
          <el-form-item>
            <el-input v-model.trim="queryForm.title" clearable placeholder="请输入语音标题" />
          </el-form-item>
          <el-form-item>
            <el-select v-model="queryForm.voiceType" clearable placeholder="请选择语音类型">
              <el-option v-for="item in voiceType" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="queryForm.classification" clearable placeholder="请选择语音分类">
              <el-option v-for="item in classification" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="queryForm.algorithmType" clearable placeholder="请选择关联算法类型">
              <el-option
                v-for="item in algorithmType"
                :key="item.algorithm_type_id"
                :label="item.title"
                :value="item.algorithm_type_id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="queryForm.status" clearable placeholder="请选择状态">
              <el-option v-for="item in status" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="fetchData">查询</el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        v-for="item in tableColumns"
        :key="item.prop"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.prop === 'addTime'">
            {{ row[item.prop] | timeFilter }}
          </span>
          <span v-else-if="item.prop === 'type'">
            {{ row[item.prop] | typeFilter(voiceType) }}
          </span>
          <span v-else-if="item.prop === 'classification'">
            {{ row[item.prop] | typeFilter(classification) }}
          </span>
          <span v-else-if="item.prop === 'algorithmType'">
            {{ row[item.prop] | algorithmTypeFilter(algorithmType) }}
          </span>
          <span v-else-if="item.prop === 'status'">
            {{ row[item.prop] | typeFilter(status) }}
          </span>
          <span v-else-if="item.prop === 'language'">
            {{ row[item.prop] | typeFilter(languageList) }}
          </span>
          <a v-else-if="item.prop === 'filename'" :href="row.filenameUrl" target="_blank">
            {{ row[item.prop] }}
          </a>
          <span v-else-if="item.prop === 'device_types'">
            {{ row[item.prop] | deviceTypesFileter(deviceType) }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <template #empty>
        <el-image class="vab-data-empty" :src="require('@/assets/empty_images/data_empty.png')" />
      </template>
      <el-table-column align="center" label="操作" width="180">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">编辑</el-button>
          <el-button type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.line"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <Edit
      ref="edit"
      :algorithm-type="algorithmType"
      :classification="classification"
      :device-type="deviceType"
      :lang="languageList"
      :status="status"
      :voice-type="voiceType"
      @fetch-data="fetchData"
    />
  </div>
</template>

<script>
  import { getList, getTypeList, deleteItem, getDeviceTypeList } from '@/api/personalizedVoice'
  import { formatDate } from '@/utils/formatDate'
  import Edit from './components/addOrEdit'

  export default {
    name: 'PersonalizedVoice',
    filters: {
      timeFilter(val) {
        if (!val || val === 0) {
          return 'N/A'
        }
        let date = val * 1000
        return formatDate(new Date(date), 'yyyy-MM-dd hh:mm:ss')
      },
      typeFilter(value, list) {
        const option = list.find((item) => item.value === value)
        return option.label || value
      },
      algorithmTypeFilter(value, list) {
        const option = list.find((item) => item.algorithm_type_id === value)
        if (option) return option.title || value
      },
      deviceTypesFileter(value, list) {
        if (!Array.isArray(value)) return value
        if (value.length === 0) return 'N/A'
        const arr = []
        value.forEach((item) => {
          const option = list.find((i) => i.type === item)
          if (option) arr.push(option.name)
        })
        return arr.join(',')
      },
    },
    components: { Edit },
    data() {
      return {
        tableColumns: [
          {
            label: '标题',
            prop: 'title',
          },
          {
            label: '语音类型',
            prop: 'type',
          },
          {
            label: '语音分类',
            prop: 'classification',
          },
          {
            label: 'UID',
            prop: 'uid',
          },
          {
            label: '关联算法类型',
            prop: 'algorithmType',
          },
          {
            label: '状态',
            prop: 'status',
          },
          {
            label: '语言',
            prop: 'language',
          },
          {
            label: '设备类型',
            prop: 'device_types',
          },
          {
            label: '音频文件',
            prop: 'filename',
          },
          {
            label: '添加时间',
            prop: 'addTime',
          },
        ],
        voiceType: [
          {
            value: 0,
            label: '进入语音',
          },
          {
            value: 1,
            label: '离开语音',
          },
        ],
        classification: [
          {
            value: 0,
            label: '系统',
          },
          {
            value: 1,
            label: '自定义',
          },
        ],
        status: [
          {
            value: 0,
            label: '不启用',
          },
          {
            value: 1,
            label: '启用',
          },
        ],
        languageList: [
          {
            label: '简体中文',
            value: 'cn',
          },
          {
            label: '繁体中文',
            value: 'cn_tw',
          },
          {
            label: '英文',
            value: 'en',
          },
          {
            label: '日语',
            value: 'jp',
          },
          {
            label: '俄语',
            value: 'ru',
          },
          {
            label: '葡萄牙语',
            value: 'pt',
          },
          {
            label: '波兰语',
            value: 'pl',
          },
          {
            label: '荷兰语',
            value: 'nl',
          },
          {
            label: '意大利语',
            value: 'it',
          },
          {
            label: '法语',
            value: 'fr',
          },
          {
            label: '西班牙语',
            value: 'es',
          },
          {
            label: '德语',
            value: 'de',
          },
        ],
        algorithmType: [],
        deviceType: [],
        list: [],
        listLoading: false,
        downloadLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          page: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
      this.getType()
      this.getDeviceType()
    },
    methods: {
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      async getType() {
        const { data } = await getTypeList()
        this.algorithmType = data
      },
      async getDeviceType() {
        const { data } = await getDeviceTypeList()
        this.deviceType = data
      },
      async handleDelete(row) {
        this.$baseConfirm('您确定删除吗？', null, async () => {
          const { msg } = await deleteItem({
            deviceVoiceId: row.id,
          })
          this.$baseMessage(msg, 'success', 'vab-hey-message-success')
          await this.fetchData()
        })
      },
      handleAdd() {
        this.$refs['edit'].showComponent()
      },
      handleEdit(row) {
        this.$refs['edit'].showComponent(row)
      },
    },
  }
</script>
