<template>
  <div class="echarts-container">
    <el-row :gutter="20">
      <el-col>
        <time-picker @userTime="userTime" />
      </el-col>
      <el-col>
        <chart-line :data="nowData" title="新增趋势" @userType="userType">
          <el-radio-group v-model="radio" @change="timeUnitChange">
            <el-radio-button
              v-for="item in timeList"
              :key="item.label"
              :disabled="item.disabled"
              :label="item.label"
            />
          </el-radio-group>
        </chart-line>
      </el-col>
      <el-col>
        <chart-collection :data="nowData.region" />
      </el-col>
      <el-col>
        <chart-table
          desc="提供所有应用在核心指标上的明细数据"
          :excel-data="excelData"
          :table-columns="tableColumns2"
          :table-data="newData"
          title="用户数据明细表"
        />
      </el-col>
      <el-col>
        <chart-Bar
          :data="regionData"
          title="用户地区分布"
          @regionType="regionType"
        />
      </el-col>
      <el-col>
        <chart-table
          :table-columns="tableColumns3"
          :table-data="regionData"
          title="用户地区明细表"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import axios from 'axios'
  import chartLine from './components/chartLine'
  import chartBar from './components/chartBar'
  import chartTable from './components/table'
  import timePicker from './components/timePicker'
  import chartCollection from './components/chartCollection'
  import { getActive, getIncrease, getRegion } from '@/api/userData'

  export default {
    name: 'Echarts',
    components: {
      chartLine,
      chartBar,
      timePicker,
      chartTable,
      chartCollection,
    },
    data() {
      return {
        tableColumns2: [
          {
            prop: 'startTime',
            label: '日期',
            type: 'time',
          },
          {
            prop: 'count',
            label: '新增用户（占比）',
            type: 'rate',
          },
          {
            prop: 'aCount',
            label: '活跃用户（占比）',
            type: 'rate',
          },
        ],
        tableColumns3: [
          {
            prop: 'countryName',
            label: '地区名',
          },
          {
            prop: '',
            label: '用户数（百分比）',
            type: 'rate',
          },
        ],
        status: 'newUser',
        regionStatus: 'countries',
        queryForm: {
          startTime: '',
          endTime: '',
          timeUnit: 'day',
        },
        nowData: [],
        newData: [],
        regionData: [],
        countries: [],
        continent: [],
        increaseData: [],
        activeData: [],
        excelData: [],
        radio: '天',
        timeList: [
          {
            label: '天',
            value: 'day',
            disabled: false,
          },
          {
            label: '周',
            value: 'week',
            disabled: true,
          },
          {
            label: '月',
            value: 'month',
            disabled: true,
          },
          {
            label: '季度',
            value: 'quarter',
            disabled: true,
          },
          {
            label: '年',
            value: 'year',
            disabled: true,
          },
        ],
      }
    },
    created() {
      this.fetchCountryData()
    },
    methods: {
      // 时间切换
      userTime(data) {
        this.queryForm.startTime = data[0] / 1000
        this.queryForm.endTime = data[1] / 1000
        this.unitFormat()
        this.suitChange()
        this.fetchData()
      },
      // 用户类型切换
      userType(data) {
        this.status = data
        this.changeData()
      },
      regionType(data) {
        this.regionStatus = data
        this.regionChangeData()
      },
      // 获取地区数据
      async fetchCountryData() {
        const {
          result: { countries, continent },
        } = await getRegion()
        this.regionData = countries
        this.countries = countries
        this.continent = continent
      },

      fetchData() {
        axios
          .all([getIncrease(this.queryForm), getActive(this.queryForm)])
          .then(
            axios.spread((res1, res2) => {
              // 两个请求现在都执行完成
              this.increaseData = res1.result
              this.activeData = res2.result
              this.changeData()
            })
          )
      },
      changeData() {
        if (this.status == 'newUser') {
          this.nowData = this.increaseData
          this.$store.commit(
            'dataStat/changeUserDataStatus',
            '新增用户（百分比）'
          )
        } else {
          this.nowData = this.activeData
          this.$store.commit(
            'dataStat/changeUserDataStatus',
            '活跃用户（百分比）'
          )
        }
        this.getAllData()
      },
      regionChangeData() {
        if (this.regionStatus === 'countries') {
          this.regionData = this.countries
          this.tableColumns3[0].prop = 'countryName'
        } else {
          this.regionData = this.continent
          this.tableColumns3[0].prop = 'continentName'
        }
      },
      // 时间类型切换
      timeUnitChange(val) {
        this.timeList.forEach((item) => {
          if (val === item.label) {
            this.queryForm.timeUnit = item.value
            this.fetchData()
          }
        })
      },
      // 判断时间类型按钮是否可用
      unitFormat() {
        if (this.queryForm.endTime - this.queryForm.startTime < 2678400 * 12) {
          this.timeList[4].disabled = true
        } else {
          this.timeList[4].disabled = false
        }
        if (this.queryForm.endTime - this.queryForm.startTime < 2678400 * 3) {
          this.timeList[3].disabled = true
        } else {
          this.timeList[3].disabled = false
        }
        if (this.queryForm.endTime - this.queryForm.startTime < 2678400) {
          this.timeList[2].disabled = true
        } else {
          this.timeList[2].disabled = false
        }
        if (this.queryForm.endTime - this.queryForm.startTime <= 604800) {
          this.timeList[1].disabled = true
        } else {
          this.timeList[1].disabled = false
        }
      },
      // 判断默认时间类型
      suitChange() {
        if (this.timeList[4].disabled === false) {
          this.queryForm.timeUnit = 'year'
          this.radio = '年'
        } else if (this.timeList[3].disabled === false) {
          this.queryForm.timeUnit = 'quarter'
          this.radio = '季度'
        } else if (this.timeList[2].disabled === false) {
          this.queryForm.timeUnit = 'month'
          this.radio = '月'
        } else if (this.timeList[1].disabled === false) {
          this.queryForm.timeUnit = 'week'
          this.radio = '周'
        } else {
          this.queryForm.timeUnit = 'day'
          this.radio = '天'
        }
      },
      getAllData() {
        let increase = this.increaseData
        increase = increase.user
        let increaseData = []
        for (let i in increase) {
          let obj = new Object()
          obj.startTime = increase[i].startTime
          obj.count = increase[i].count
          obj.rate = increase[i].rate
          increaseData[i] = obj
        }
        let active = this.activeData
        active = active.user
        let activeData = []
        for (let i in active) {
          let obj = new Object()
          obj.aCount = active[i].count
          obj.acRate = active[i].rate
          activeData[i] = obj
        }
        this.mixData(increaseData, activeData)
      },

      mixData(increaseData, activeData) {
        let getData = increaseData
        let getDataNo = activeData
        let newData = []
        for (let i in getData) {
          let obj = new Object()
          obj.startTime = getData[i].startTime
          obj.count = getData[i].count
          obj.rate = getData[i].rate
          obj.aCount = getDataNo[i].aCount
          obj.acRate = getDataNo[i].acRate
          newData[i] = obj
        }
        this.newData = newData
        this.getExcelData(newData)
      },

      getExcelData(data) {
        let excelData = data
        let newData = []
        for (let i in excelData) {
          let obj = new Object()
          obj.startTime = excelData[i].startTime
          obj.count = excelData[i].count + '(' + excelData[i].rate + '%' + ')'
          obj.aCount =
            excelData[i].aCount + '(' + excelData[i].acRate + '%' + ')'
          newData[i] = obj
        }
        this.excelData = newData
      },
    },
  }
</script>

<style lang="scss" scoped>
  .echarts-container {
    padding: 0 !important;
    background: $base-color-background !important;
    ::v-deep {
      .echarts {
        width: 100%;
        height: 280px;
      }
    }
  }
</style>
