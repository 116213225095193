var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "100px", model: _vm.form } },
        [
          _c("el-input", {
            attrs: {
              clearable: "",
              placeholder: "请输入理由",
              rows: 5,
              type: "textarea",
            },
            model: {
              value: _vm.form.hint,
              callback: function ($$v) {
                _vm.$set(
                  _vm.form,
                  "hint",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.hint",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }