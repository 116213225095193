<template>
  <div>
    <!-- ECharts 容器 -->
    <el-button type="primary" @click="exportToExcel">导出 Excel</el-button>
    <div ref="chart" style="width: 100%; height: 500px; margin-top: 20px"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts/core'
  import { TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LegendComponent } from 'echarts/components'
  import { LineChart } from 'echarts/charts'
  import { UniversalTransition } from 'echarts/features'
  import { CanvasRenderer } from 'echarts/renderers'
  import { callTodayCloudPackageQueryNum } from '@/api/cloudData'
  import * as XLSX from 'xlsx'

  echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
  ])

  export default {
    name: 'MyChartComponent',
    myChart: false,

    data() {
      return {
        dataList: [],
        chartData: {
          dates: [],
          values: {},
        },
      }
    },
    mounted() {
      this.fetchData()
    },
    created() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        try {
          const response = await callTodayCloudPackageQueryNum()
          if (response.code === 0) {
            this.dataList = response.data.list
            this.formatChartData(this.dataList)
            this.initChart()
          }
        } catch (error) {
          console.error('Failed to fetch data:', error)
        }
      },
      formatChartData(dataList) {
        const dates = []
        const values = { test: [], eu: [], cn: [], us: [], all: [] }

        dataList.forEach((item) => {
          // Collect dates
          if (!dates.includes(item.date)) {
            dates.push(item.date)
          }

          // Collect values by region and date
          values[item.region].push(item.value)
        })

        this.chartData.dates = dates
        this.chartData.values = values
      },
      initChart() {
        const chartDom = this.$refs.chart
        const myChart = echarts.init(chartDom)

        const option = {
          title: {
            text: '每日云存列表界面访问次数',
          },
          legend: {
            data: ['Test', 'EU', 'CN', 'US', 'All'],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          tooltip: {
            trigger: 'axis',
          },
          xAxis: {
            type: 'category',
            data: this.chartData.dates,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: 'Test',
              data: this.chartData.values.test,
              type: 'line',
            },
            {
              name: 'EU',
              data: this.chartData.values.eu,
              type: 'line',
            },
            {
              name: 'CN',
              data: this.chartData.values.cn,
              type: 'line',
            },
            {
              name: 'US',
              data: this.chartData.values.us,
              type: 'line',
            },
            {
              name: 'All',
              data: this.chartData.values.all,
              type: 'line',
            },
          ],
        }
        myChart.setOption(option)
      },
      exportToExcel() {
        // 定义区域映射关系
        const regions = ['test', 'eu', 'cn', 'us', 'all']
        const regionNames = {
          test: '测试服',
          eu: '欧洲服',
          cn: '中国服',
          us: '美洲服',
          all: '总计',
        }

        // 获取排序后的日期列表
        const dates = [...new Set(this.dataList.map((item) => item.date))].sort((a, b) => {
          return new Date(a) - new Date(b)
        })

        // 创建日期-区域值映射表
        const dateMap = {}
        dates.forEach((date) => {
          dateMap[date] = { date }
          regions.forEach((region) => {
            dateMap[date][region] = 0 // 默认值设为0
          })
        })

        // 填充实际数据
        this.dataList.forEach((item) => {
          if (dateMap[item.date] && regions.includes(item.region)) {
            dateMap[item.date][item.region] = item.value
          }
        })

        // 构建Excel数据
        const header = ['日期', ...regions.map((r) => regionNames[r])]
        const excelData = [header]
        dates.forEach((date) => {
          const row = [date]
          regions.forEach((region) => {
            row.push(dateMap[date][region])
          })
          excelData.push(row)
        })

        // 创建并导出Excel文件
        const worksheet = XLSX.utils.aoa_to_sheet(excelData)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, '访问数据')
        XLSX.writeFile(workbook, '云存访问统计.xlsx')
      },
    },
  }
</script>
