import request from '@/utils/request'

export function getStoreMealList(params) {
  return request({
    url: 'unicom/manage/filter/user',
    method: 'get',
    params,
  })
}

export function getFilter(params) {
  return request({
    url: 'unicom/manage/filter/user',
    method: 'get',
    params,
  })
}

export function getFlowPackages(params) {
  return request({
    url: 'unicom/manage/orderFlowPackage',
    method: 'get',
    params,
  })
}

export function getIccidStatus(params) {
  return request({
    url: 'unicom/manage/sim-info',
    method: 'get',
    params,
  })
}

export function getComboType(params) {
  return request({
    url: 'unicom/manage/getFlowComboList',
    method: 'get',
    params,
  })
}

export function resetCardPackage(data) {
  return request({
    url: '/unicom/manage/resetCardPackage',
    method: 'post',
    data,
  })
}

export function getPackageDetails(params) {
  return request({
    url: 'unicom/manage/getPackageDetails',
    method: 'get',
    params,
  })
}

export function asyncUpdateStatus(data) {
  return request({
    url: 'unicom/manage/asyncUpdateStatus',
    method: 'post',
    data,
  })
}

export function iccidBatchReset(data) {
  return request({
    url: 'unicom/manage/iccidBatchReset',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}

export function updateFlowComboById(data) {
  return request({
    url: 'unicom/manage/updateFlowComboById',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}

export function batchOrderFlowPackage(data) {
  return request({
    url: 'unicom/manage/batchOrderFlowPackage',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}

export function batchSerialNumberCombo(data) {
  return request({
    url: 'unicom/manage/batchSerialNumberCombo',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}
