var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "100px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "存储桶名称", prop: "bucket" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.bucket,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "bucket",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.bucket",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "描述", prop: "content" } },
            [
              _c("el-input", {
                attrs: { type: "content" },
                model: {
                  value: _vm.form.content,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "content",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "国内外", prop: "mold" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择国内外" },
                  model: {
                    value: _vm.form.mold,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "mold", $$v)
                    },
                    expression: "form.mold",
                  },
                },
                _vm._l(_vm.moldList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "国家", prop: "area" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.area,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "area",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.area",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "地域", prop: "region" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.region,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "region",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.region",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "存储桶节点", prop: "endpoint" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.endpoint,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "endpoint",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.endpoint",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否免费", prop: "is_free" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择是否免费" },
                  model: {
                    value: _vm.form.is_free,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_free", $$v)
                    },
                    expression: "form.is_free",
                  },
                },
                _vm._l(_vm.isFreeList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "存储天数", prop: "storeDay" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.storeDay,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "storeDay",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.storeDay",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "大洲编号", prop: "region_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择大洲编号" },
                  model: {
                    value: _vm.form.region_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "region_id", $$v)
                    },
                    expression: "form.region_id",
                  },
                },
                _vm._l(_vm.regionIdList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }