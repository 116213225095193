<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="1500px"
    @close="close"
  >
    <el-descriptions
      border
      :column="8"
      direction="vertical"
      style="padding-bottom: 20px"
    >
      <el-descriptions-item
        v-for="item in commonColumns"
        :key="item.val"
        :label="item.label"
        :span="item.span"
      >
        <span v-if="item.label === '更新时间'">
          {{ list[item.val] | timeFormat }}
        </span>
        <span
          v-else-if="
            item.label === '是否支持4G' ||
            item.label === '是否支持自定义语音' ||
            item.label === '是否支持云台'
          "
        >
          {{ list[item.val] | supportFormat }}
        </span>
        <span v-else>
          {{ list[item.val] }}
        </span>
      </el-descriptions-item>
    </el-descriptions>
    <el-table ref="table" border :data="list.uid_push">
      <el-table-column
        v-for="item in tabelColumns"
        :key="item.prop"
        align="center"
        :formatter="item.formatter"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template slot-scope="scope">
          <span v-if="item.label === '添加时间' || item.label === '更新时间'">
            {{ scope.row[item.prop] | timeFormat }}
          </span>
          <span v-else-if="item.label === 'app类型'">
            {{ scope.row[item.prop] | appTypeFormat }}
          </span>
          <span v-else-if="item.label === '推送类型'">
            {{ scope.row[item.prop] | pushTypeFormat }}
          </span>
          <span v-else>
            {{ scope.row[item.prop] }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
  import { formatDate } from '@/utils/formatDate'

  export default {
    name: 'Details',
    filters: {
      timeFormat(val) {
        return formatDate(new Date(val * 1000), 'yyyy-MM-dd')
      },
      appTypeFormat(val) {
        if (val === 1) {
          return 'ios'
        } else if (val === 2) {
          return '安卓'
        }
        return val
      },
      pushTypeFormat(val) {
        if (val === 0) {
          return 'apns'
        } else if (val === 1) {
          return '安卓gcm'
        } else if (val === 2) {
          return '极光'
        } else if (val === 3) {
          return '华为'
        } else if (val === 4) {
          return '小米'
        } else if (val === 5) {
          return 'vivo'
        } else if (val === 6) {
          return 'oppo'
        } else if (val === 7) {
          return '魅族'
        }
        return val
      },
      supportFormat(val) {
        if (val === 0) {
          return '不支持'
        } else if (val === 1) {
          return '支持'
        }
        return val
      },
    },
    data() {
      return {
        list: [],
        title: '详情',
        loading: true,
        dialogVisible: false,
        queryForm: {
          UID: '',
        },
        commonColumns: [
          {
            label: '推送间隔',
            val: 'detect_interval',
          },
          {
            label: '检测类型',
            val: 'ai_type',
          },
          {
            label: '是否支持4G',
            val: 'mobile_4g',
          },
          {
            label: '是否支持自定义语音',
            val: 'is_custom_voice',
          },
          {
            label: '是否支持云台',
            val: 'is_ptz',
          },
          {
            label: '设备规格码',
            val: 'ucode',
          },
          {
            label: '设备时区',
            val: 'tz',
          },
          {
            label: '更新时间',
            val: 'updTime',
          },
          {
            label: '消息通知Json',
            val: 'msg_notify',
          },
        ],
        tabelColumns: [
          {
            label: '用户名',
            prop: 'username',
          },
          {
            label: '用户ID',
            prop: 'userID',
            width: '180',
          },
          {
            label: '设备功能表',
            prop: 'uid_set',
          },
          {
            label: 'appID',
            prop: 'appBundleId',
          },
          {
            label: 'app类型',
            prop: 'app_type',
          },
          {
            label: '推送类型',
            prop: 'push_type',
          },
          {
            label: '设备验证令牌',
            prop: 'token_val',
            width: '130',
          },
          {
            label: '极光推送令牌',
            prop: 'jg_token_val',
          },
          {
            label: '手机唯一标识',
            prop: 'm_code',
            width: '210',
          },
          {
            label: '推送语言类型',
            prop: 'lang',
          },
          {
            label: 'utc时区',
            prop: 'tz',
          },
          {
            label: '添加时间',
            prop: 'addTime',
          },
          {
            label: '更新时间',
            prop: 'updTime',
          },
        ],
      }
    },
    methods: {
      showDetails(data) {
        this.dialogVisible = true
        this.list = data
      },
      close() {
        this.dialogVisible = false
        this.list = []
      },
    },
  }
</script>
