<template>
  <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogFormVisible" width="500px" @close="close">
    <el-form ref="form" label-width="120px" :model="form" :rules="rules">
      <el-form-item label="语音标题" prop="title">
        <el-input v-model="form.title" clearable placeholder="请输入语音标题" />
      </el-form-item>
      <el-form-item label="语音类型" prop="type">
        <el-select v-model="form.type" clearable placeholder="请选择语音类型">
          <el-option v-for="item in voiceType" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="语音分类" prop="classification">
        <el-select v-model="form.classification" clearable placeholder="请选择语音分类">
          <el-option v-for="item in classification" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="!edit && form.classification === 1" label="uid" prop="uid">
        <el-input v-model.trim="form.uid" clearable placeholder="请输入uid" />
      </el-form-item>
      <el-form-item label="关联算法类型" prop="algorithmType">
        <el-select v-model="form.algorithmType" clearable placeholder="请选择关联算法类型">
          <el-option
            v-for="item in algorithmType"
            :key="item.algorithm_type_id"
            :label="item.title"
            :value="item.algorithm_type_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="form.status" clearable placeholder="请选择语音状态">
          <el-option v-for="item in status" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="语言" prop="language">
        <el-select v-model="form.language" clearable placeholder="请选择语言">
          <el-option v-for="item in lang" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="设备类型" prop="device_types">
        <el-select v-model="form.device_types" clearable multiple placeholder="请选择设备类型">
          <el-option v-for="item in deviceType" :key="item.type" :label="item.name" :value="item.type" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="!edit" label="语音文件" prop="voiceFile">
        <el-upload
          ref="upload"
          accept="audio/*"
          action="/upload"
          :auto-upload="false"
          class="upload"
          :http-request="upLoadFile"
          :limit="1"
          :on-change="handleChange"
          :on-exceed="handleExceed"
        >
          <el-button slot="trigger" size="small" type="primary">选择文件</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">保 存</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { add, edit } from '@/api/personalizedVoice'
  export default {
    name: 'AddOrUpdate',
    props: {
      algorithmType: {
        type: [Object, Array],
        default: () => {},
      },
      classification: {
        type: [Object, Array],
        default: () => {},
      },
      status: {
        type: [Object, Array],
        default: () => {},
      },
      voiceType: {
        type: [Object, Array],
        default: () => {},
      },
      lang: {
        type: [Object, Array],
        default: () => {},
      },
      deviceType: {
        type: [Object, Array],
        default: () => {},
      },
    },
    data() {
      return {
        form: {
          voiceFile: [],
        },
        rules: {
          title: [{ required: true, message: '请输入语音标题', trigger: 'blur' }],
          type: [{ required: true, message: '请选择语音类型', trigger: 'blur' }],
          classification: [{ required: true, message: '请选择语音分类', trigger: 'blur' }],
          algorithmType: [{ required: true, message: '请选择关联算法类型', trigger: 'blur' }],
          status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
          language: [{ required: true, message: '请选择语言', trigger: 'blur' }],
          voiceFile: [{ required: true, message: '请上传语音文件', trigger: 'change' }],
          uid: [{ required: true, message: '请输入uid', trigger: 'blur' }],
        },
        dialogFormVisible: false,
        edit: false,
        title: '',
        loadingInstance: null,
      }
    },
    methods: {
      showComponent(row) {
        this.title = row ? '编辑语音' : '添加语音'
        this.edit = !!row
        this.form = row ? { ...row } : { voiceFile: [] } // 使用展开运算符
        this.dialogFormVisible = true
      },
      upLoadFile(file) {
        this.formData.append('voiceFile', file.file)
      },
      handleChange(file, fileList) {
        this.form.voiceFile = fileList // 更新文件列表
        this.$refs.form.validateField('voiceFile') // 触发验证
      },
      handleExceed(files) {
        this.$refs.upload.clearFiles()
        this.$nextTick(() => {
          this.$refs.upload.handleStart(files[0])
        })
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        if (!this.edit) this.$refs.upload.clearFiles()
        if (this.loadingInstance) {
          this.loadingInstance.close() // 关闭 loading
        }
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (!valid) return
          this.loadingInstance = this.$loading({
            lock: true,
            text: '正在上传...',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.7)',
          })
          this.formData = new FormData()
          if (!this.form.id) this.$refs.upload.submit()
          if (this.form.id) this.formData.append('deviceVoiceId', this.form.id)
          if (this.form.uid) this.formData.append('uid', this.form.uid)
          this.formData.append('title', this.form.title)
          this.formData.append('voiceType', this.form.type)
          this.formData.append('classification', this.form.classification)
          this.formData.append('algorithmType', this.form.algorithmType)
          this.formData.append('status', this.form.status)
          this.formData.append('language', this.form.language)
          this.formData.append('deviceTypes', this.form.device_types)
          try {
            const { msg } = this.form.id ? await edit(this.formData) : await add(this.formData)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          } catch (error) {
            console.error(error)
            this.$baseMessage('保存失败，请重试', 'error')
          } finally {
            if (this.loadingInstance) {
              this.loadingInstance.close() // 关闭 loading
            }
          }
        })
      },
    },
  }
</script>
