var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "unicomStat-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [_c("el-col", [_c("roughTable")], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }