<template>
  <div>
    <!-- ECharts 容器 -->
    <div ref="chart" style="width: 100%; height: 500px; margin-top: 20px"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts/core'
  import { TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LegendComponent } from 'echarts/components'
  import { LineChart } from 'echarts/charts'
  import { UniversalTransition } from 'echarts/features'
  import { CanvasRenderer } from 'echarts/renderers'
  import { callTodayCloudPackageQueryNum } from '@/api/cloudData'

  echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
  ])

  export default {
    name: 'MyChartComponent',
    myChart: false,

    data() {
      return {
        dataList: [],
        chartData: {
          dates: [],
          values: {},
        },
      }
    },
    mounted() {
      this.fetchData()
    },
    created() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        try {
          const response = await callTodayCloudPackageQueryNum()
          if (response.code === 0) {
            this.dataList = response.data.list
            this.formatChartData(this.dataList)
            this.initChart()
          }
        } catch (error) {
          console.error('Failed to fetch data:', error)
        }
      },
      formatChartData(dataList) {
        const dates = []
        const values = { test: [], eu: [], cn: [], us: [], all: [] }

        dataList.forEach((item) => {
          // Collect dates
          if (!dates.includes(item.date)) {
            dates.push(item.date)
          }

          // Collect values by region and date
          values[item.region].push(item.value)
        })

        this.chartData.dates = dates
        this.chartData.values = values
      },
      initChart() {
        const chartDom = this.$refs.chart
        const myChart = echarts.init(chartDom)

        const option = {
          title: {
            text: '每日云存列表界面访问次数',
          },
          legend: {
            data: ['Test', 'EU', 'CN', 'US', 'All'],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          tooltip: {
            trigger: 'axis',
          },
          xAxis: {
            type: 'category',
            data: this.chartData.dates,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: 'Test',
              data: this.chartData.values.test,
              type: 'line',
            },
            {
              name: 'EU',
              data: this.chartData.values.eu,
              type: 'line',
            },
            {
              name: 'CN',
              data: this.chartData.values.cn,
              type: 'line',
            },
            {
              name: 'US',
              data: this.chartData.values.us,
              type: 'line',
            },
            {
              name: 'All',
              data: this.chartData.values.all,
              type: 'line',
            },
          ],
        }

        myChart.setOption(option)
      },
    },
  }
</script>
